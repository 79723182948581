const DateHelper = {
  dateToDisplay: (date) => {
    date = date && date.length > 0 ? (date + '--').split('-') : date;
    return date && date.length > 0
      ? date[2] + '/' + date[1] + '/' + date[0]
      : date;
  },

  displayToDate: (date) => {
    date = date && date.length > 0 ? (date + '//').split('/') : date;
    return date && date.length > 0
      ? date[2] + '-' + date[1] + '-' + date[0]
      : date;
  },

  getFormattedDate: (date) => {
    const dt = new Date(date),
      day = dt.getDate().toString().padStart(2, '0'),
      month = (dt.getMonth() + 1).toString().padStart(2, '0'),
      year = dt.getFullYear();

    return day + '/' + month + '/' + year;
  },
};

export default DateHelper;
