import React from "react";
import { Container } from "react-bootstrap";
import NavigationBar from "components/navigation-bar/navigation-bar";
import styles from "./styles.module.scss";

function Wrapper({
  withNavbar,
  navTitle,
  navReturnRoute,
  withoutContainer,
  goBackHidden,
  ...rest
}) {
  return (
    <div className={`${styles.wrapper} ${withNavbar && styles.withNav}`}>
      {withNavbar && (
        <NavigationBar title={navTitle} returnRoute={navReturnRoute} goBackHidden={goBackHidden} />
      )}
      {withoutContainer ? (
        <div className={rest.className}>
          {rest.children}
        </div>
      ) : (
        <Container fluid className="mx-auto h-100 d-flex flex-column mb-1">
          {rest.children}
        </Container>
      )}
    </div>
  );
}
export default React.memo(Wrapper);