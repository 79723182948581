import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import WhatsAppIcon from "assets/images/whatsapp-icon.png";
import InstagramIcon from "assets/images/instagram-icon.png";
import YoutubeIcon from "assets/images/youtube-icon.png";
import FacebookIcon from "assets/images/facebook-icon.png";
import EmailIcon from "assets/images/email-icon.png";
import PrintIcon from "assets/images/print-icon.png";

import routes from "navigation/routes";

import styles from "./styles.module.scss";

export default function Social({
  whatsappLink,
  emailLink,
  facebookLink,
}) {
  const history = useHistory();

  const addPrintUtmCampaing = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('utm_campaign', 'print');

    window.history.replaceState(null, null, url.href);
  };

  const removePrintUtmCampaign = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('utm_campaign');

    window.history.replaceState(null, null, url.href);
  };

  const socialPrintButtonClick = () => {
    addPrintUtmCampaing();
    window.print();
  };

  useEffect(() => {
    window.onafterprint = () => {
      removePrintUtmCampaign();
    };

    return () => {
      window.onafterprint = null;
    }
  }, []);

  return (
    <div className={styles.social}>
      <p>Compartilhar via:</p>
      <div className={styles.line} />

      <div className={styles.socialButtonsContainer}>
        <a href={whatsappLink} target="_blank" rel="noreferrer" id="mpk_share_whatsapp">
          <img src={WhatsAppIcon} />
        </a>

        <button onClick={() => history.push(routes.MULTIPLIK_MEDIA_KIT)} id="mpk_share_instagram">
          <img src={InstagramIcon} />
        </button>

        <button onClick={() => history.push(routes.MULTIPLIK_MEDIA_KIT)} id="mpk_share_youtube">
          <img src={YoutubeIcon} />
        </button>

        <a href={facebookLink} target="_blank" rel="noreferrer" id="mpk_share_facebook">
          <img src={FacebookIcon} />
        </a>

        <a href={emailLink} target="_blank" rel="noreferrer" id="mpk_share_email">
          <img src={EmailIcon} />
        </a>

        <button onClick={socialPrintButtonClick} id="mpk_share_print">
          <img src={PrintIcon} />
        </button>
      </div>
    </div>
  );
}
