const BANK_LIST = [
  {
    code: '1',
    name: 'Banco do Brasil',
  },
  {
    code: '104',
    name: 'Caixa Econômica Federal',
  },
  {
    code: '237',
    name: 'Bradesco',
  },
  {
    code: '33',
    name: 'Santander',
  },
  {
    code: '341',
    name: 'Itaú',
  },
  {
    code: '41',
    name: 'Banrisul',
  },
  {
    code: '77',
    name: 'Banco Inter',
  },
  {
    code: '260',
    name: 'Nubank',
  },
];

export default BANK_LIST;
