import API from 'config/api';
import * as Authentication from 'utils/helpers/authentication-helper';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { inflate } from "pako";

const REFRESH_TIMEOUT = 300000; // 5 minutes
let listenerStarted = false;

const AuthenticationService = {
  waitingRefreshToken: () => {
    return localStorage.getItem('doRefreshToken') === '1';
  },
  isRefreshTokenRequest: (response) => {
    return response.config.url === '/api/authenticate/refresh_token';
  },
  startListenerRefreshToken: () => {
    if (listenerStarted) {
      return;
    }
    AuthenticationService.refreshToken();
    if (!listenerStarted) {
      listenerStarted = true;
    }
  },
  refreshToken: () => {
    if (Authentication.isAuthenticated()) {
      setTimeout(() => {
        if (AuthenticationService.waitingRefreshToken()) {
          API.put('/login/refresh_token').then(
            (response) => {
              localStorage.setItem('doRefreshToken', '0');
            },
            (error) => {
              localStorage.setItem('doRefreshToken', '1');
            },
          );
        }
        AuthenticationService.refreshToken();
      }, REFRESH_TIMEOUT); // 5 minutes
    }
  },
  getCookie: (cookieName) => {
    return Cookies.get(cookieName);
  },
  decodeAndInflateToken: (oauth2Token) => {
    const compressedToken = jwt.decode(oauth2Token);
    if (compressedToken.authorities) {
      return compressedToken; // Not compressed, possible old auth version
    }
    const compressedBodyBin = new Uint8Array(JSON.parse(compressedToken.compressed));
    const inflatedBody = inflate(compressedBodyBin);
    compressedToken.authorities = String.fromCharCode(...inflatedBody)
    return compressedToken;
  },
  hasAuthority: (authority) => {
    const oauth2Token = AuthenticationService.getCookie('oauth2Token');
    if (jwt && oauth2Token) {
      const token = AuthenticationService.decodeAndInflateToken(oauth2Token);
      const authorities = jwt ? token.authorities : null;
      if (authorities) {
        return authorities.includes(authority.toUpperCase());
      }
    }
    return false;
  },
  hasAnyAuthorities: (authorities) => {
    let has = false;
    if (authorities && authorities.length > 0) {
      authorities.forEach((a) => {
        if (AuthenticationService.hasAuthority(a)) {
          has = true;
        }
      });
    }
    return has;
  },
  hasAuthorities: (authorities) => {
    if (Array.isArray(authorities) && authorities.length > 0) {
      let has = true;
      authorities.forEach((a) => {
        if (!AuthenticationService.hasAuthority(a)) {
          has = false;
        }
      });
      return has;
    }
    return false;
  },
  getUser() {
    const oauth2Token = AuthenticationService.getCookie('oauth2Token');
    if (jwt && oauth2Token) {
      const token = AuthenticationService.decodeAndInflateToken(oauth2Token);
      return token;
    }
    return null;
  },
  /**
   * @Deprecated - Use the authentication-action instead.
   */
  authenticate(username, password) {
    return API.post(
      '/login/authenticate',
      { username, password },
      {
        headers: {
          'Content-Type': 'application/json',
          'SuperSim-Persistent-Log': JSON.stringify({
            device: { kind: 1, code: null },
          }),
        },
      },
    );
  },
  /**
   * @Deprecated - Use the authentication-action instead.
   */
  logout() {
    return API.delete('/login/logout', {
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({
          device: { kind: 1, code: null },
        }),
      },
    });
  },
  changePassword(username, validationCode, password) {
    return API.post(
      `/login/change?username=${username}`,
      {
        validationCode,
        newPassword: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'SuperSim-Persistent-Log': JSON.stringify({
            device: { kind: 1, code: null },
          }),
        },
      },
    );
  },
  requestChangePassword(username, program) {
    return API.get(`/login/change/request?username=${username}&program=${program}`, {
      headers: {
        'Content-Type': 'application/json',
        'SuperSim-Persistent-Log': JSON.stringify({
          device: { kind: 1, code: null },
        }),
      },
    });
  },
};

export default AuthenticationService;
