import React from 'react';
import { Row, Col } from 'react-bootstrap';
import MaterialCard from 'components/material-card/material-card';
import Wrapper from 'components/wrapper/wrapper';
import { mediaKit } from './data';
import styles from './styles.module.scss';

function MediaKit() {
  return (
    <Wrapper navTitle="Materiais de apoio" withNavbar>
      <h2 className={styles.pageTitle}>Materiais recentes</h2>
      <Row>
        {mediaKit.map((material) => (
          <Col className="mt-3" xl={3} lg={4} md={6} sm={12} key={material.id}>
            <MaterialCard materialInformation={material} />
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
}

export default MediaKit;
