export const NETWORK_STATE_ACTION = 'network-state-action';

/**
 * Update the state of the network.
 *
 * @param {boolean} state
 */
export function updateNetworkState(state) {
  return {
    type: NETWORK_STATE_ACTION,
    payload: state,
  }
}
