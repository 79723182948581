import { createAction } from "redux-api-middleware";

export const FIND_SELLER = "/seller/find";
export const FIND_SELLER_SUCCESS = "/seller/find/success";
export const FIND_SELLER_FAILURE = "/seller/find/failure";

/**
 * Find seller information, with seller, person and totals.
 *
 * @param {number} sellerId Seller id.
 * @return Object
 */
export const findSeller = (sellerId) =>
  createAction({
    endpoint: `/api/seller/${sellerId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    types: [FIND_SELLER, FIND_SELLER_SUCCESS, FIND_SELLER_FAILURE],
  });
