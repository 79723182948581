const UrlHelper = {
  getQueryParam: (variable) => {
    var query = window.location.search.substring(1);
    var vars = query?.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i]?.split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }

    return false;
  },

  isLogin: () =>
    window.location.href.includes("/login") ||
    window.location.href.endsWith("/"),

  checkIfUrlParamsIsBase64: (param) => {
    if (!isNaN(Number(param))) return param;

    try {
      return window.atob(param);
    } catch (err) {
      return param;
    }
  },
};

export default UrlHelper;
