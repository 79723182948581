import React from 'react';
import { HashIcon } from "components/ui/icons/icons";
import "./notification-style.css"

const NotificationComponent = (props) => {
      return (
        <div className="notification-multiplik" onClick={props.openModal}>
          <section>
            <div className="round-hash-icon">
              <HashIcon width={40} height={60}/>
            </div>
          </section>
          <section className="notification-content">
            <b className="notification-title">{props.title}</b>
            <div style={{color: "#2A2B35"}}>{props.description}</div>
            <div style={{color: "#757575", marginTop: "5px"}}>{props.date}</div>
          </section>
        </div>
      );
};

export default NotificationComponent;
