import history from "navigation/history";

const routes = {
  ROOT: "/",
  LOGIN: "/",
  CHANGE_PASSWORD: "/senha",
  REQUEST_CHANGE_PASSWORD: "/login/solicitar-nova-senha",

  HOME: "/home",

  SUPPORT_MATERIALS: "/materiais-de-apoio",

  NET: "/rede",
  NET_SELLER_NEW: "/rede/vendedor/novo",
  NET_SELLER_EDIT: "/rede/vendedor/editar/:id",
  NET_SELLER_FOLDER: "/rede/vendedor/folder/:id",
  NET_SELLER_LOANS: "/rede/vendedor/emprestimos/:id",
  NET_SELLER_QRCODE: "/rede/vendedor/qrcode/:id",

  SELLER_REGISTER: "/vendedor/novo",
  APP_INSTRUCTION: "/app/instruction",
  PARTNER_CONVERSION: "/parceiro/conversao",
  SELLER_FAQ: "/faq",
  SERVICE_PROVISION: "/servicos",
  SERVICE_PROVISION_STEP: "/servicos/:service",

  LOAN_START: "/emprestimo/inicio",
  LOAN_APPLICATION_START: "/emprestimo/solicitacao/inicio",
  LOAN_APPLICATION_PRE_CONTRACT: "/emprestimo/solicitacao/informacoes-contrato",
  LOAN_APPLICATION_CONTRACT: "/emprestimo/solicitacao/contrato",
  LOAN_APPLICATION_POST_CONTRACT: "/emprestimo/solicitacao/dados-obrigatorios",
  LOAN_APPLICATION_DOCUMENT_PROOF: "/emprestimo/solicitacao/documento",
  LOAN_APPLICATION_FILE_UPLOAD: "/emprestimo/solicitacao/documento2",
  LOAN_APPLICATION_OUROCEL_APP_LIGHT_UNLOCK: "/emprestimo/solicitacao/app-sim",
  LOAN_APPROVED: "/emprestimo/aprovado",
  LOAN_DECLINED: "/emprestimo/solicitacao/nao-disponivel",
  LOAN_UNDERWRITING: "/emprestimo/solicitacao/analise",
  LOAN_ONGOING: "/emprestimo/ativo",

  LOAN_APPLICATION_CONTRACT_TIMER: "/emprestimo/solicitacao/contrato/cupom",
  LOAN_APPLICATION_MOBILE_COLLATERAL_CONSENT:
    "/emprestimo/solicitacao/celular/aceite",
  LOAN_APPLICATION_OUROCEL_PRECONTRACT:
    "/emprestimo/solicitacao/celular/informacoes-contrato",
  LOAN_APPLICATION_OUROCEL_APP_UNLOCK: "/emprestimo/solicitacao/celular/app",

  // Multiplik
  MULTIPLIK_ROOT: "/multiplik",
  MULTIPLIK_LOGIN: "/multiplik/entrar",
  MULTIPLIK_NEW: "/multiplik/novo",
  MULTIPLIK_HOME: "/multiplik/home",
  MULTIPLIK_NOTIFICATIONS: "/multiplik/notifications",
  MULTIPLIK_MEDIA_KIT: "/multiplik/media-kit",
  MULTIPLIK_FAQ: "/multiplik/faq",
  MULTIPLIK_RULES: "/multiplik/regras",
  MULTIPLIK_BANK: "/multiplik/novo/dados-bancarios",
  MULTIPLIK_CONFIG: "/multiplik/configuracoes",
  MULTIPLIK_ONLINE_LANDING_PAGE: "/multiplik/online",

  // Multiplik Offline
  MULTIPLIK_OFFLINE_LANDING_PAGE: "/multiplik/offline",
  MULTIPLIK_OFFLINE_POP_REGISTER: "/multiplik/offline/pop-novo",
  MULTIPLIK_OFFLINE_POP_EDIT: "/multiplik/offline/pop-editar",

  // Superset
  SUPERSET_DASHBOARD_PARTNER: "/partner/dashboard",

  // Partner
  PARTNER: "/partner",

  goToRoute: (route, isExternal = false, newTab = false, attribute = null) => {
    if (attribute) {
      route = route + "?";

      for (const [index, [key, value]] of Object.entries(attribute).entries()) {
        if (index > 0 && index < Object.entries(attribute).length) {
          route = route + "&";
        }

        route = route + key + "=" + value;
      }
    }

    if (isExternal) {
      setTimeout(() => {
        newTab ? window.open(route, "_blank") : (window.location = route);
      }, 100);
    } else {
      history.push(route);
    }
  },
};

export default routes;

export function getRouteForLoan(
  loan,
  defaultRoute = routes.LOAN_APPLICATION_START,
) {
  // Route.
  let route = defaultRoute;

  // If there is a loan and a loan state.
  if (loan && loan.state) {
    // If the loan is in the application phase.
    if ([1].includes(loan.state.phase.id)) {
      // If it is in the created state.
      if ([1, 3, 4].includes(loan.state.id)) {
        // Returns the basic application route.
        route = routes.LOAN_APPLICATION_START;
      }
      // If it is in the basic application state.
      else if ([5].includes(loan.state.id)) {
        // Returns the pre-contract application route.
        route = routes.LOAN_APPLICATION_PRE_CONTRACT;
      }
      // If it is in the contract ready state.
      else if ([6].includes(loan.state.id)) {
        // Returns the contract application route.
        route = routes.LOAN_APPLICATION_CONTRACT;
      }
      // If it is in the contract signed state.
      else if ([7].includes(loan.state.id)) {
        // Returns the post-contract application route.
        route = routes.LOAN_APPLICATION_POST_CONTRACT;
      }
      // If the loan is depending on proof of income.
      else if (loan.application.financial?.bankStatement?.availableFiles == 0) {
        // Returns the proof of income route.
        route = routes.LOAN_APPLICATION_DOCUMENT_PROOF;
      }
      // If it is in the pending mobile collateral.
      else if (
        loan.application.pendingSteps.includes(6) ||
        loan.application.pendingSteps.includes(8)
      ) {
        // Returns the pending collateral route.
        route = routes.LOAN_APPLICATION_OUROCEL_APP_LIGHT_UNLOCK;
      }
      // If it is not in the pending proof of income.
      else {
        // Returns the underwriting route.
        route = routes.LOAN_UNDERWRITING;
      }
    }

    // If the loan is in the underwriting phase.
    else if ([2].includes(loan.state.phase.id)) {
      const FILE_UPLOAD_ROUTE = sessionStorage.getItem("currentRoute");

      // If the loan is depending on proof of income.
      if (loan.application.financial?.bankStatement?.availableFiles == 0) {
        // Returns the proof of income route.
        route = routes.LOAN_APPLICATION_DOCUMENT_PROOF;
      } else if (FILE_UPLOAD_ROUTE) {
        route = FILE_UPLOAD_ROUTE;
      }
      // If it is in the pending mobile collateral.
      else if (
        loan.application.pendingSteps.includes(6) ||
        loan.application.pendingSteps.includes(8)
      ) {
        // Returns the pending collateral route.
        route = routes.LOAN_APPLICATION_OUROCEL_APP_LIGHT_UNLOCK;
      }
      // If it is not in the pending proof of income.
      else {
        // Returns the underwriting route.
        route = routes.LOAN_UNDERWRITING;
      }
    }
    // If the loan is declined.
    else if ([3].includes(loan.state.phase.id)) {
      // Returns the declined route.
      route = routes.LOAN_DECLINED;
    }
    // If the loan state is approved.
    else if ([4].includes(loan.state.phase.id)) {
      // Returns the approved route.
      route = routes.LOAN_APPROVED;
    }
    // If the loan state is on going.
    else if (
      [5, 6, 7].includes(loan.state.phase.id) ||
      [100, 102].includes(loan.state.id)
    ) {
      // Returns the ongoing route.
      route = routes.LOAN_ONGOING;
    }
  }

  // Returns the route.
  return route;
}

export function goToLoanRoute(
  loan,
  currentRoute,
  defaultRoute = routes.LOAN_APPLICATION_START,
) {
  // If the user should be in a different route.
  let route = getRouteForLoan(loan, defaultRoute);

  // If there is a route.
  if (route && route !== currentRoute) {
    //console.log("Going to route " + route)
    history.push(route + history.location.search);
  }
}
