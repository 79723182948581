import React from "react";

import Hero from "./hero";
import Link from "./link";
import Social from "./social";

import styles from "./styles.module.scss";

export default function Invite({
  url,
  whatsappLink,
  emailLink,
  facebookLink,
  person,
  seller,
  performance,
}) {
  return (
    <div className={styles.container}>
      <Hero name={person?.firstName} level={seller?.level} />
      <Link url={url} />
      <Social
        whatsappLink={whatsappLink}
        emailLink={emailLink}
        facebookLink={facebookLink}
      />
    </div>
  );
}
