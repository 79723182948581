const ErrorHelper = {
  getMessage: (error) => {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 500
    ) {
      return 'Ocorreu um erro interno.';
    }
    return error &&
      error.response &&
      error.response.data &&
      error.response.data[0] &&
      error.response.data[0].content
      ? error.response.data[0].content
      : 'Ocorreu um erro interno.';
  },

  removeDuplicates: (arr) => {
    var obj = {};
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
      obj[arr[i]] = true;
    }
    for (var key in obj) {
      ret_arr.push(key);
    }
    return ret_arr;
  },
};

export default ErrorHelper;
