import React from "react";
import { useHistory } from "react-router-dom";

import HeroImage from "assets/images/imageHero.png";

import { setEventOnDataLayer } from "utils/helpers/data-helper";

import routes from "navigation/routes";

import styles from "./styles.module.scss";

export default function Hero({ name, level }) {
  const history = useHistory();

  function onClickCta() {
    setEventOnDataLayer("mpk_banner_gatilho_nivel" + level);

    history.push(`${routes.MULTIPLIK_FAQ}`);
  }

  return (
    <div className={styles.hero}>
      <p>
        <b>Oi{name ? `, ${name}` : ""}!</b> Temos novidades.
      </p>

      <div className={styles.banner}>
        <img src={HeroImage} width="106" />

        <div className={styles.info}>
          <p>
            <span>Importante!</span>
            <br />
            Atualização de modelo de comissionamento.
          </p>

          <button onClick={onClickCta}>Saiba mais</button>
        </div>
      </div>
    </div>
  );
}
