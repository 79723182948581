import React from 'react';

import Header from 'components/header/header';
import PartnerTable from './partner-table';
import Wrapper from "components/wrapper/wrapper";

export default function partnerDashboard() {
  return (
    <>
      <Header
        showLogoutLogo={true}
        showMenu={false}
      />
      <Wrapper navTitle="Dashboard" withNavbar goBackHidden />
      <PartnerTable partner="CPL" />
    </>
  );
}
