import React, { useState } from "react";
import { data } from "./data";
import Button from "components/button/button";
import SellerService from "service/seller-service";
import { setEventOnDataLayer } from 'utils/helpers/data-helper';
import styles from "./styles.module.scss";

export default function Onboarding({
  setShowOnboarding,
  passwordUpdated,
  setShowRequestPasswordDialog,
  showPasswordDialog,
  person,
  isNotification
}) {
  const [step, setStep] = useState(1);
  const isLastStep = step === 4;

  function goToNextStep() {
    if (isLastStep) {
      closeModal();

      return;
    }

    setStep(step + 1);
  }

  function learnMore() {
    setEventOnDataLayer(isNotification ? "mpk_notification_onboarding" : "mpk_onboarding_zap");
    window.open("https://whatsapp.com/channel/0029VaA58Zp5q08i8ElPAG2S?utm_source=area-logada&utm_medium=card-onboarding&utm_campaign=canal-zap", "_blank");
    closeModal();
  }

  function closeModal() {
    if(showPasswordDialog) {
      const personId = person?.data?.id;
      let details = {
        hasFinishedOnboarding: "true"
      }
      SellerService.setMultiplikSellerDetails(personId, details);

      if (!passwordUpdated) setShowRequestPasswordDialog(true);
    }
    setShowOnboarding(false);
  }

  return (
    <div className={styles.container}>
      {data.map((item) => {
        if (item.id !== step) return null;

        return (
          <>
            <div className={styles.header} key={item.item}>
              <div />

              <img src={item.image} alt="Onboarding Multiplik" />

              <div className={styles.dots}>
                {data.map((item, key) => (
                  <span key={item.item} style={step === key + 1 ? { background: "linear-gradient(79.59deg, #FE690A 0%, #FDAC17 100%)" } : null} />
                ))}
              </div>
            </div>

            <div className={styles.content}>
              <div>
                <h3>{item.title}</h3>

                <p>{item.description}</p>
              </div>

              <Button id={isLastStep ? (isNotification ? "mpk_notification_onboarding_follow_whatsapp" : "mpk_onboarding_follow_whatsapp") : ""} onClick={() => { isLastStep ? (learnMore(), closeModal()) : goToNextStep(); }}>
                {isLastStep ? "Seguir canal" : "Próximo"}
              </Button>

              {isLastStep ? (
                <span href="" onClick={closeModal}>Depois</span>
              ) : null}
            </div>
          </>
        );
      })}
    </div>
  );
}
