import React, { useState, useEffect } from 'react';
import { Button } from "lib-ui-react";
import { NotificationManager } from "react-notifications";

import MaskedFormControl from "components/ui/form/masked-input/masked-input";
import Wrapper from 'components/wrapper/wrapper';

import { businessKind, invoicing } from './data';

import BR_STATES from 'utils/constants/brazilian-states';
import BANKS from 'utils/constants/banks';
import { setEventOnDataLayer } from 'utils/helpers/data-helper';

import routes from "navigation/routes";

import MultiplikService from 'service/multiplik-service';
import AuthenticationService from "service/authentication-service";

import styles from './styles.module.scss';

export default function SellerConfig() {
  const [formValues, setFormValues] = useState({
    businessName:  "",
    corporateId: "",
    businessKind: "",
    businessInvoicing: "",
    zipCode: "",
    state: "",
    city: "",
    neighborhood: "",
    streetAddress: "",
    streetNumber: "",
    complement: "",
    bankAccountNumber: "",
    bankCode: "",
    branchNumber: "",
    bankAccountKind: "1",
    bankAccountType: "1",
    name: "",
    phoneNumber: "",
    taxId: "",
    emailAddress: "",
  });
  const [loadingUpdateBankData, setLoadingUpdateBankData] = useState(false);
  const [loadingUpdateSellerRegister, setLoadingUpdateSellerRegister] = useState(false);
  const [seller, setSeller] = useState(null);
  const isEditPopPage = window.location.href.includes("pop-editar");
  const isMultiplikOffline = JSON.parse(sessionStorage.getItem("isMultiplikOffline"));

  useEffect(() => {
    const user = AuthenticationService.getUser();
    const userName = user?.aud;

    if (userName != null && seller == null) {
      getBusinessDetailsData(userName);
    }

    if (!isMultiplikOffline) {
      getPerson();
    }
  }, [seller]);

  async function getBusinessDetailsData(id) {
    const seller = await MultiplikService.findOrCreateSellerFromUser(id);

    setSeller(seller);

    const { businessName, corporateId, businessKind, businessInvoicing, bankAccountType } = seller?.data?.businessDetails;
    const {
      zipCode, state, city, neighborhood, streetAddress, streetNumber, complement,
    } = seller?.data?.businessDetails?.businessAddress;
    const {
      number: bankAccountNumber, bankCode, branchNumber, checkDigit, kind: bankAccountKind,
    } = seller?.data?.businessDetails?.bankAccount;

    if (isMultiplikOffline || bankAccountType == 2) {
      setFormValues({
        businessName,
        corporateId,
        businessKind,
        businessInvoicing,
        zipCode,
        state,
        city,
        neighborhood,
        streetAddress,
        streetNumber,
        complement,
        bankAccountNumber: bankAccountNumber + "-" + String(checkDigit),
        bankCode: bankCode,
        branchNumber: branchNumber,
        bankAccountKind: bankAccountKind || "1",
        bankAccountType,
      });
    } else {
      setFormValues({
        ...formValues,
        corporateId,
      });
    }
  }

  function getPerson() {
    MultiplikService.findMemberData()
      .then(({ data }) => {
        const {
          bankCode, branchNumber, checkDigit, number, kind,
        } = data.finance.primaryBankAccount || "";

        const businessDetailsBankAccount = seller?.data?.businessDetails?.bankAccount;
        const isCorporateBankAccount = seller?.data?.businessDetails?.bankAccountType == 2;

        if (seller) {
          setFormValues({
            ...formValues,
            bankAccountNumber: isCorporateBankAccount ? (businessDetailsBankAccount?.number ?(businessDetailsBankAccount?.number + "-" + String(businessDetailsBankAccount?.checkDigit)) : "") : (number + "-" + String(checkDigit)),
            bankCode: isCorporateBankAccount ? businessDetailsBankAccount?.bankCode : bankCode,
            branchNumber: isCorporateBankAccount ? businessDetailsBankAccount?.branchNumber : branchNumber,
            bankAccountKind: (isCorporateBankAccount ? businessDetailsBankAccount?.kind : kind) || "1",
            bankAccountType: seller?.data?.businessDetails?.bankAccountType || "1",
            phoneNumber: data?.contacts?.primaryPhoneNumber?.number?.toString()?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'),
            taxId: data?.documents?.taxId?.number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
            emailAddress: data?.contacts?.primaryEmailAddress?.address,
            businessName: seller?.data?.businessDetails?.businessName ? seller?.data?.businessDetails?.businessName : data?.name,
            corporateId: seller?.data?.businessDetails?.corporateId,
          });
        }
      });
  }

  function disableButton() {
    if (isMultiplikOffline) {
      for (const key in formValues) {
        if (
          key !== 'invoicing' &&
        key !== 'complement' &&
        (
          formValues[key] === null ||
          formValues[key] === undefined ||
          !formValues["bankAccountNumber"].includes("-") ||
          isNaN(formValues["bankAccountNumber"].slice(-1)) ||
          (typeof formValues[key] === 'string' && formValues[key].trim() === '')
        )
        ) {
          return true;
        }
      }
      return false;
    } else {
      if (!formValues.bankAccountNumber || !formValues.branchNumber || !formValues["bankAccountNumber"].includes("-") ||
      isNaN(formValues["bankAccountNumber"].slice(-1)) || !formValues.bankCode || !formValues.bankAccountKind ||
      !formValues.bankAccountType || (formValues.bankAccountType == 2 && (!formValues?.corporateId || formValues?.corporateId?.length < 14))) {
        return true;
      }

      return false;
    }
  }

  function onChangeInput(event) {
    const value = (event?.target?.name === 'zipcode' || event?.target?.name === 'corporateId') ?
      event?.target?.value?.replace(/\D/g, '') :
      event.target.value;

    setFormValues({
      ...formValues,
      [event.target.name]: value,
    });
  }

  function updateBusinessDetails() {
    MultiplikService.saveBusinessDetails(formValues)
      .then(() => {
        NotificationManager.success("Dados atualizados com sucesso");
        if (!isEditPopPage) {
          setTimeout(() => routes.goToRoute(routes.MULTIPLIK_HOME), 3000);
        }
      })
      .catch(() => {
        NotificationManager.error("Aconteceu um erro ao atualizar os dados");
      })
      .finally(() => {
        setLoadingUpdateBankData(false);
        setLoadingUpdateSellerRegister(false);
      });
  }

  function updateBankAccountData() {
    if (isMultiplikOffline) {
      updateBusinessDetails();
    } else {
      const details = {
        bankAccountNumber: formValues.bankAccountNumber,
        bankCode: formValues.bankCode,
        branchNumber: formValues.branchNumber,
        bankAccountKind: formValues.bankAccountKind,
        bankAccountType: formValues.bankAccountType,
        corporateId: formValues.corporateId,
      };

      updateSellerDetails(details);
    }
  }

  function updateSellerRegister() {
    if (isMultiplikOffline) {
      updateBusinessDetails();
    } else {
      updateSellerName();
    }
  }

  function updateSellerName() {
    const details = {
      businessName: formValues.businessName,
    };

    updateSellerDetails(details);
  }

  function updateSellerDetails(details) {
    MultiplikService.updateDetails(details)
      .then(() => {
        NotificationManager.success("Dados atualizados com sucesso");
        routes.goToRoute(routes.MULTIPLIK_HOME);
      })
      .catch(() => {
        NotificationManager.error("Aconteceu um erro ao atualizar os dados");
      })
      .finally(() => {
        setLoadingUpdateBankData(false);
        setLoadingUpdateSellerRegister(false);
      });
  }

  return (
    <Wrapper navTitle="Meus dados" withNavbar>
      <div className={styles.container}>
        {((isMultiplikOffline && isEditPopPage) || !isMultiplikOffline) && (
          <>
            <h2 className="mt-3">Dados bancários</h2>

            <p className={styles.subtitle}>
              <span>Importante!</span> Para o sucesso da entrega de suas recompensas,
              preencha os dados de acordo com a <b>titularidade</b> da conta cadastrada.
            </p>

            <div className={styles.form}>
              <div className={styles.formControl}>
                <label>Selecione o tipo de conta<span>*</span></label>
                <select name="bankAccountKind" onChange={(event) => onChangeInput(event)} value={formValues.bankAccountKind}>
                  <option value="" disabled selected>Tipo de conta</option>
                  <option value={1}>Conta corrente</option>
                  <option value={2}>Conta poupança</option>
                </select>
              </div>

              <div className={styles.formControl}>
                <label>Banco<span>*</span></label>
                <select name="bankCode" onChange={(event) => onChangeInput(event)} value={formValues.bankCode}>
                  <option value="" disabled selected>Selecione o banco</option>

                  {BANKS.map((item) => (
                    <option value={item.code} key={item.code}>{item.name}</option>
                  ))}
                </select>
              </div>

              <div className={styles.formControl}>
                <label>Conta CPF ou CNPJ?<span>*</span></label>
                <select name="bankAccountType" onChange={(event) => onChangeInput(event)} value={formValues.bankAccountType}>
                  <option value={1}>CPF</option>
                  <option value={2}>CNPJ</option>
                </select>
              </div>

              {formValues.bankAccountType == 2 && (
                <div className={styles.formControl}>
                  <label> CNPJ<span>*</span></label>
                  <MaskedFormControl
                    name="corporateId"
                    type="text"
                    maskChar={false}
                    mask="99.999.999/9999-99"
                    value={formValues.corporateId}
                    onChange={(event) => onChangeInput(event)}
                  />
                </div>
              )}

              <div className={styles.formControl}>
                <label>Agência (sem dígito)<span>*</span></label>
                <input type="text" maxLength="5" name="branchNumber" onChange={(event) => onChangeInput(event)} value={formValues.branchNumber} />
              </div>

              <div className={styles.formControl}>
                <label>Conta (com dígito)<span>*</span></label>
                <input type="text" name="bankAccountNumber" onChange={(event) => onChangeInput(event)} value={formValues.bankAccountNumber} />
              </div>

              <Button
                onClick={() => {
                  setLoadingUpdateBankData(true);
                  updateBankAccountData();
                  setEventOnDataLayer("mpk_update_bank_account");
                }}
                loading={loadingUpdateBankData}
                disabled={loadingUpdateBankData || disableButton()}
                label="Atualizar dados bancários"
              />
            </div>
          </>
        )}

        <h2 className="mt-5 mb-4">{isMultiplikOffline ? "Cadastro do estabelecimento" : "Cadastro do usuário"}</h2>

        {isMultiplikOffline ? (
          <div className={styles.form}>
            <div className={styles.formControl}>
              <label>Nome da loja / ponto comercial<span>*</span></label>
              <input type="text" name="businessName" onChange={(event) => onChangeInput(event)} value={formValues?.businessName} />
            </div>

            <div className={styles.formControl}>
              <label>CNPJ<span>*</span></label>
              <MaskedFormControl
                mask="99.999.999/9999-99"
                type="text"
                name="corporateId"
                value={formValues.corporateId}
                onChange={(event) => onChangeInput(event)}
                maskChar={false}
                disabled={isEditPopPage}
              />
            </div>

            <div className={styles.formControl}>
              <label>Ramo de atividade<span>*</span></label>
              <select name="businessKind" onChange={(event) => onChangeInput(event)} disabled={isEditPopPage} value={formValues?.businessKind}>
                <option value="" disabled selected>Selecione a atividade</option>

                {businessKind.map((item) => (
                  <option value={item.value} key={item.label}>{item.label}</option>
                ))}
              </select>
            </div>

            <div className={styles.formControl}>
              <label>Faturamento<span>*</span></label>

              <select name="businessInvoicing" onChange={(event) => onChangeInput(event)} disabled={isEditPopPage} value={formValues?.businessInvoicing}>
                <option value="" disabled selected>Selecione o faturamento</option>

                {invoicing.map((item) => (
                  <option value={item.value} key={item.value}>{item.label}</option>
                ))}
              </select>
            </div>

            <div className={styles.formControl}>
              <label>CEP<span>*</span></label>

              <MaskedFormControl
                mask="99999-999"
                type="text"
                name="zipCode"
                value={formValues.zipCode}
                onChange={(event) => onChangeInput(event)}
                maskChar={false}
              />
            </div>

            <div className={styles.formControl}>
              <label>Estado<span>*</span></label>
              <select name="state" onChange={(event) => onChangeInput(event)} value={formValues.state}>
                <option value="" disabled selected>Selecione um estado</option>

                {BR_STATES.map((item) => (
                  <option key={item.abbreviation} value={item.abbreviation}>{item.name}</option>
                ))}
              </select>
            </div>

            <div className={styles.formControl}>
              <label>Cidade<span>*</span></label>
              <input type="text" name="city" onChange={(event) => onChangeInput(event)} value={formValues.city} />
            </div>

            <div className={styles.formControl}>
              <label>Bairro<span>*</span></label>
              <input type="text" name="neighborhood" onChange={(event) => onChangeInput(event)} value={formValues.neighborhood} />
            </div>

            <div className={styles.formControl}>
              <label>Rua<span>*</span></label>
              <input type="text" name="streetAddress" onChange={(event) => onChangeInput(event)} value={formValues.streetAddress} />
            </div>

            <div className={styles.formControl}>
              <label>Número<span>*</span></label>
              <input type="number" name="streetNumber" onChange={(event) => onChangeInput(event)} value={formValues.streetNumber} />
            </div>

            <div className={styles.formControl}>
              <label>Complemento</label>
              <input type="text" name="complement" onChange={(event) => onChangeInput(event)} value={formValues.complement != "null" ? formValues.complement : ""} />
            </div>

            <Button
              onClick={() => {
                setLoadingUpdateSellerRegister(true);
                updateSellerRegister();
                setEventOnDataLayer("mpk_update_register");
              }}
              loading={loadingUpdateSellerRegister}
              disabled={loadingUpdateSellerRegister}
              label="Atualizar cadastro"
            />
          </div>
        ) : (
          <div className={styles.form}>
            <div className={styles.formControl}>
              <label>Nome completo<span>*</span></label>
              <input type="text" name="businessName" onChange={(event) => onChangeInput(event)} value={formValues.businessName} />
            </div>

            <div className={styles.formControl}>
              <label>E-mail<span>*</span></label>
              <input type="text" value={formValues.emailAddress} disabled />
            </div>

            <div className={styles.formControl}>
              <label>CPF<span>*</span></label>
              <input type="text" value={formValues.taxId} disabled />
            </div>

            <div className={styles.formControl}>
              <label>Número do celular<span>*</span></label>
              <input type="text" value={formValues.phoneNumber} disabled />
            </div>

            <Button
              onClick={() => {
                setLoadingUpdateSellerRegister(true);
                updateSellerRegister();
                setEventOnDataLayer("mpk_update_register");
              }}
              loading={loadingUpdateSellerRegister}
              disabled={loadingUpdateSellerRegister || !formValues.businessName}
              label="Atualizar cadastro"
            />
          </div>
        )}

      </div>
    </Wrapper>
  );
}
