import API from "config/api";

const LeadService = {

  saveLead: (payload, origin) => {
    var body = JSON.stringify(payload).replace(/"\s+|\s+"/g, '"');

    return API.post(`/lead/new?origin=${origin}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        "SuperSim-Persistent-Log": JSON.stringify({
          device: { kind: 1,
            code: null },
        }),
      },
    });
  },
};

export default LeadService;
