import API from "config/api";

const MultiplikService = {
  findMemberData: () => API.get(`/multiplik/person`),
  getInvitationLink: () => API.get(`/multiplik/invitation-link`),
  getPushNotifications: () => API.get(`/multiplik/push-notifications`),
  findOrCreateSellerFromUser: () =>
    API.get(`/multiplik/find-or-create-seller`),

  createMultiplikSeller: (seller) => {
    return API.post("/multiplik/create_member", seller, {
      headers: {
        "Content-Type": "multipart/form-data",
        "SuperSim-Persistent-Log": JSON.stringify({
          device: { kind: 1,
            code: null },
        }),
      },
    });
  },

  updateDetails: (seller) => {
    return API.post("/multiplik/update-details", seller, {
      headers: {
        "SuperSim-Persistent-Log": JSON.stringify({
          device: { kind: 1,
            code: null },
        }),
      },
    });
  },
  checkMultiplikMember: () => API.get(`/multiplik/check-multiplik`),
  findSellerSales: (filters) => {
    return API.get(
      `/multiplik/invitations?page=${filters.page}&pageSize=${filters.pageSize}&from=${filters.dateStart}&to=${filters.dateEnd}`,
    );
  },
  getPerformance: (from, to) => API.get(`/multiplik/performance?from=${from}&to=${to}`),
  saveBusinessDetails: (details) => {
    var data = new FormData();

    for (var key in details) {
      data.append(key, details[key]);
    }

    return API.put(`/multiplik/business-details/save`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "SuperSim-Persistent-Log": JSON.stringify({
          device: { kind: 1,
            code: null },
        }),
      },
    });
  },
};

export default MultiplikService;
