import React from 'react';

import Header from 'components/header/header';
import MultiplikBar from './multiplik-bar';
import Differentials from './differentials';
import Impact from './impact';
import Partners from './partners';

export default function MultiplikOffline() {
  return (
    <>
      <Header showMenu={false}/>
      <MultiplikBar />
      <Impact />
      <Differentials />
      <Partners />
    </>
  );
}
