import { useEffect } from 'react';

function useInfiniteScroll(containerRef, loadMoreCallback) {
  useEffect(() => {
    const container = containerRef.current;

    function handleScroll() {
      if (container.scrollHeight - container.scrollTop === container.clientHeight) {
        loadMoreCallback();
      }
    }

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, loadMoreCallback]);
}

export default useInfiniteScroll;
