const ValidationHelper = {
  checkValidity: (value, rules) => {
    let isValid = true;

    value =
      typeof value === 'string' || value instanceof String ? value : value + '';

    let trimedValue = value;
    if (!rules.checkbox) {
      trimedValue = value.trim();
    }

    if (rules.required) {
      isValid = trimedValue !== '' && isValid;
    }

    if (rules.photo && rules.required){
      isValid = value != null && isValid;
    }

    if (rules.minLength && (rules.required || trimedValue !== '')) {
      isValid = trimedValue.length >= rules.minLength && isValid;
    }

    if (rules.maxLength && (rules.required || trimedValue !== '')) {
      isValid = trimedValue.length <= rules.maxLength && isValid;
    }

    if (rules.passwordConfirmation && (rules.required || trimedValue !== '')) {
      isValid = trimedValue === rules.password && isValid;
    }

    if (rules.email && (rules.required || trimedValue !== '')) {
      isValid =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          trimedValue.toLowerCase()
        ) && isValid;
    }

    if (rules.taxId && (rules.required || trimedValue !== '')) {
      let taxIdValid = true;
      const taxId = trimedValue.replace(/\D/g, '');
      const isRepeatingNumber = (str) => /^(\d)(\1){10}$/.test(str);

      if (
        taxId === '' ||
        taxId.length !== 11 ||
        !/^\d{11}$/.test(taxId) ||
        isRepeatingNumber(taxId)
      ) {
        taxIdValid = false;
      }

      const digits = taxId.split('').map((x) => parseInt(x));

      for (let j = 0; j < 2; j++) {
        let sum = 0;
        for (let i = 0; i < 9 + j; i++) {
          sum += digits[i] * (10 + j - i);
        }
        let checkDigit = 11 - (sum % 11);
        if (checkDigit === 10 || checkDigit === 11) {
          checkDigit = 0;
        }
        if (checkDigit !== digits[9 + j]) {
          taxIdValid = false;
        }
      }

      isValid = taxIdValid && isValid;
    }

    if (rules.numberOnly && (rules.required || trimedValue !== '')) {
      isValid = /^\d+$/.test(trimedValue) && isValid;
    }

    if (rules.mobilePhoneNumber && (rules.required || trimedValue !== '')) {
      isValid =
        /^(\(((1[1-9])|(2[12478])|(3[1234578])|(4[1-9])|(5[1345])|(6[1-9])|(7[134579])|(8[1-9])|(9[1-9]))\)\s)((9[0-9]{2})|(7[0789]))[0-9]{2}-[0-9]{4}/i.test(
          trimedValue
        ) && isValid;
    }

    if (rules.payDay && (rules.required || trimedValue !== '')) {
      isValid =
        new RegExp('^((30)|([12]?[0-9]))$').test(trimedValue) && isValid;
    }

    if (rules.date && (rules.required || trimedValue !== '')) {
      isValid =
        /^(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.](\d{4})$/.test(
          trimedValue
        ) && isValid;
      if (isValid) {
        let today = new Date().getTime(),
          idate = trimedValue.split('/');
        idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
        isValid = !(today - idate < 0 ? true : false);
      }
    }

    if (rules.branchNumber && (rules.required || trimedValue !== '')) {
      isValid = /[0-9]{0,5}/g.test(trimedValue) && isValid;
    }

    if (rules.bankAccountNumber && (rules.required || trimedValue !== '')) {
      isValid = /[0-9]{4,11}-[0-9a-zA-Z]{1}$/g.test(trimedValue) && isValid;
    }

    if (rules.zipCode && (rules.required || trimedValue !== '')) {
      isValid =
        (/[0-9]{5}-[\d]{3}/g.test(trimedValue) ||
          /[0-9]{8}/g.test(trimedValue)) &&
        isValid;
    }

    if (rules.city && (rules.required || trimedValue !== '')) {
      isValid = /.{3,100}/g.test(trimedValue) && isValid;
    }

    if (rules.neighborhood && (rules.required || trimedValue !== '')) {
      isValid = /.{2,100}/g.test(trimedValue) && isValid;
    }

    if (rules.streetAddress && (rules.required || trimedValue !== '')) {
      isValid = /.{2,100}/g.test(trimedValue) && isValid;
    }

    if (rules.streetNumber && (rules.required || trimedValue !== '')) {
      isValid = /.{1,10}/g.test(trimedValue) && isValid;
    }

    if (rules.complement && (rules.required || trimedValue !== '')) {
      isValid = /.{0,50}/i.test(trimedValue) && isValid;
    }

    if (rules.idNumber && (rules.required || trimedValue !== '')) {
      isValid = /[0-9]{3,14}/i.test(trimedValue) && isValid;
    }


    return isValid;
  },
};

export default ValidationHelper;
