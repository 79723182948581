import React from 'react';
import { useSelector } from "react-redux";
import { formatCorporateTaxId } from 'utils/helpers/format-helper';
import User from 'assets/images/user-icon.png';
import styles from './styles.module.scss';

export default function InfluenceLevel({ name, level, influencerTaxId, bankAccountType, style }) {
  const seller = useSelector((state) => state.seller.seller)?.data;
  const corporateTaxId = formatCorporateTaxId(seller?.businessDetails?.corporateId);

  const progressBarBorder =
      level == 1 ?
        styles.progressBorder1:
        level == 2 ?
          styles.progressBorder2:
          level == 3 ?
            styles.progressBorder3 :
            level == 4 ?
              styles.progressBorder4 : null;

  function renderInfluencerLevel() {
    switch (level) {
    case 0:
      return "Aprendiz";
    case 1:
      return "Iniciante";
    case 2:
      return "Intermediário";
    case 3:
      return "Avançado";
    case 4:
      return "Profissional";
    }
  }

  return (
    <div style={style} className={`d-flex align-items-center ${styles.container}`}>
      <div className={`${styles.progress} ${progressBarBorder}`}></div>

      <div className={styles.userImage}>
        <img src={User} alt="Multiplik" />
      </div>

      {name &&
        <div className="mt-1">
          <h2>{name}</h2>
          {level !== undefined && (
            <p>ID {seller.partyId} • <span>Nível {renderInfluencerLevel()}</span></p>
          )}
          {bankAccountType === 2 ? (
            <p><span>CNPJ</span> {corporateTaxId}</p>
          ) : (
            <p><span>CPF</span> {influencerTaxId}</p>
          )}
        </div>
      }
    </div>
  );
}
