export function formatTaxId(taxId){
  taxId = taxId?.replace(/[^\d]/g, "");

  return taxId?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCorporateTaxId(corporateTaxId){
  corporateTaxId = corporateTaxId?.replace(/\D/g, '');

  return corporateTaxId?.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5',
  );
}
