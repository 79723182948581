import { RSAA } from 'redux-api-middleware';
import Cookies from 'js-cookie';
import StorageHelper from '../../utils/helpers/storage-helper';

export const AUTHENTICATE = '/authenticate';
export const AUTHENTICATE_SUCCESS = '/authenticate/success';
export const AUTHENTICATE_FAILURE = '/authenticate/failure';

export const LOGOUT = '/logout';
export const LOGOUT_SUCCESS = '/logout/success';
export const LOGOUT_FAILURE = '/logout/failure';

export function authenticate(username, password) {
  return {
    [RSAA]: {
      endpoint: `/api/login/authenticate`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
      types: [AUTHENTICATE, {
        type: AUTHENTICATE_SUCCESS,
        payload: async (action, state, res) => {
          const oAuthToken = await res.json();
          Cookies.set('oauth2Token', oAuthToken?.accessToken?.tokenValue);
          Cookies.set('oauth2RefreshToken', oAuthToken?.refreshToken?.tokenValue);
          localStorage.setItem('loggedIn', 'true');
          // StorageHelper.removeStateStorage();
          return oAuthToken;
        },
      }, AUTHENTICATE_FAILURE],
    },
  };
}

export function logout() {
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('multiplikMember');
  Cookies.remove('oauth2Token');
  Cookies.remove('oauth2RefreshToken');
  Cookies.remove('SESSION');
  StorageHelper.removeStateStorage();
  return {
    [RSAA]: {
      endpoint: `/api/login/logout`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    },
  };
}
