const PartnerKindHelper = {
  POP: 0,
  LOAN: 1,
  CONSULTANT: 2,
  OTHER: 99,
  canCreateServices: (partnerKind) => {
    return PartnerKindHelper.isPop(partnerKind)
      || PartnerKindHelper.isConsultant(partnerKind);
  },
  canCreateNewSeller: (partnerKind) => {
    return PartnerKindHelper.isPop(partnerKind)
      || PartnerKindHelper.isLoan(partnerKind)
      || PartnerKindHelper.isConsultant(partnerKind);
  },
  isPop: (partnerKindId) => {
    return partnerKindId === PartnerKindHelper.POP;
  },
  isLoan: (partnerKind) => {
    return partnerKind === PartnerKindHelper.LOAN;
  },
  isConsultant: (partnerKind) => {
    return partnerKind === PartnerKindHelper.CONSULTANT;
  },
  translate: (partnerKind) => {
    switch (partnerKind.data) {
      case PartnerKindHelper.POP:
        return "Ponto de apoio";
      case PartnerKindHelper.LOAN:
        return "Empréstimo";
      case PartnerKindHelper.CONSULTANT:
        return "Consultor";
      default:
        return "Outro";
    }
  },
};

export { PartnerKindHelper };
