const UtilHelper = {
  copyToClipboard: (textToCopy) => {
    const textField = document.createElement("textarea");
    textField.innerText = textToCopy;

    document.body.appendChild(textField);

    textField.select();

    document.execCommand("copy");

    textField.remove();
  },

  formataCPF: (cpf) => {
    return cpf.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      function (regex, argumento1, argumento2, argumento3, argumento4) {
        return (
          argumento1 + "." + argumento2 + "." + argumento3 + "-" + argumento4
        );
      },
    );
  },

  scrollToTop: () => {
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      //
    }
  },

  scrollContentToTop: () => {
    try {
      document
        .getElementsByClassName("content")?.[0]
        ?.scroll({ top: 0,
          behavior: "smooth" });
    } catch (e) {
      //
    }
  },

  getBoolean: (value) => {
    if (value === true || value === false || value == null) {
      return value;
    } else {
      return value === "true";
    }
  },

  downloadCSV: (content, name) => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/csv" });
    element.href = URL.createObjectURL(file);

    element.download = name;

    document.body.appendChild(element);

    element.click();
  },

  getAddressFromGoogleGeocode: (geocode) => {
    const addressComponents = geocode.data[0]?.results[0]?.address_components;

    if (addressComponents === undefined) {
      return null;
    }

    let address = {};

    addressComponents.forEach((component) => {
      if (component.types?.includes("postal_code")) {
        address.zipcode = {
          longName: component.long_name,
          shortName: component.short_name,
        };
      } else if (component.types?.includes("route")) {
        address.street = {
          longName: component.long_name,
          shortName: component.short_name,
        };
      } else if (component.types?.includes("sublocality")) {
        address.neighborhood = {
          longName: component.long_name,
          shortName: component.short_name,
        };
      } else if (component.types?.includes("administrative_area_level_2")) {
        address.city = {
          longName: component.long_name,
          shortName: component.short_name,
        };
      } else if (component.types?.includes("administrative_area_level_1")) {
        address.state = {
          longName: component.long_name,
          shortName: component.short_name,
        };
      } else if (component.types?.includes("country")) {
        address.country = {
          longName: component.long_name,
          shortName: component.short_name,
        };
      }
    });

    return address;
  },

  formatAccountNumberWithCheckDigit: (accountNumber) => {
    if (accountNumber == null) {
      return "";
    }

    if (accountNumber.includes("-")) {
      return accountNumber;
    }

    return [accountNumber.slice(0, -1), "-", accountNumber.slice(-1)].join("");
  },

  formatNumberToCurrency: (value) => {
    if (value == null) {
      return "";
    }

    let formatedValue = value.toLocaleString('pt-br',{ style: 'currency',
      currency: 'BRL' });

    return formatedValue ?? null;
  },
};

export default UtilHelper;
