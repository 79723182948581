import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';

import styles from "./styles.module.scss";

import CalendarIcon from "assets/images/calendar-icon.png";
import ArrowDown from "assets/images/arrow-down.png";

export default function Filter({ setDateFrom, setDateTo }) {
  const filterRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [period, setPeriod] = useState(10);

  const filters = [
    {
      label: "Hoje",
      value: 1,
    },
    {
      label: "Ontem",
      value: 2,
    },
    {
      label: "Esta semana - dom. até Hoje",
      value: 3,
    },
    {
      label: "7 dias atrás",
      value: 4,
    },
    {
      label: "Semana passada - de dom. a sáb.",
      value: 5,
    },
    {
      label: "14 dias atrás",
      value: 6,
    },
    {
      label: "Este mês",
      value: 7,
    },
    {
      label: "30 dias atrás",
      value: 8,
    },
    {
      label: "Último mês",
      value: 9,
    },
    {
      label: "Todo o período",
      value: 10,
    },
  ];

  useEffect(() => {
    // If clicked on outside of element
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  function selectFilterPeriod(value) {
    setTimePeriod(value);
    setOpenFilter(false);
    renderFilterLabel();
    setPeriod(value);
  }

  function renderFilterLabel() {
    const label = filters.filter((item) => item.value === period);

    return label[0]?.label;
  }

  function setTimePeriod(value) {
    switch (value) {
    case 1:
      setDateFrom(moment());
      setDateTo(moment());
      break;

    case 2:
      setDateFrom(moment().subtract(1, "days"));
      setDateTo(moment().subtract(1, "days"));
      break;

    case 3:
      setDateFrom(moment().startOf('week'));
      setDateTo(moment());
      break;

    case 4:
      setDateFrom(moment().subtract(7, "days"));
      setDateTo(moment());
      break;

    case 5:
      setDateFrom(moment().subtract(1, 'weeks').startOf('week'));
      setDateTo(moment().subtract(1, 'weeks').endOf('week'));
      break;

    case 6:
      setDateFrom(moment().subtract(14, "days"));
      setDateTo(moment());
      break;

    case 7:
      setDateFrom(moment().startOf('month'));
      setDateTo(moment());
      break;

    case 8:
      setDateFrom(moment().subtract(30, "days"));
      setDateTo(moment());
      break;

    case 9:
      setDateFrom(moment().subtract(1,'months').startOf('month'));
      setDateTo(moment().subtract(1,'months').endOf('month'));
      break;

    case 10:
      setDateFrom(moment().subtract(100, "years"));
      setDateTo(moment());
      break;
    }
  }

  return (
    <>

      <div className={styles.filter}>
        <img src={CalendarIcon} alt="Ícone de calendário" />
        <p onClick={() => setOpenFilter(true)}><b>Filtrar resultados</b> ({renderFilterLabel()})</p>
        <img src={ArrowDown} alt="Ícone do filtro" onClick={() => setOpenFilter(true)} />
      </div>

      {openFilter && (
        <div className={styles.indicationsFilters} id="indicationsFilters" ref={filterRef}>
          {filters.map((item) => (
            <span key={item.value} onClick={() => selectFilterPeriod(item.value)}>{item.label}</span>
          ))}
        </div>
      )}</>
  );
}
