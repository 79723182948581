import React, { useState } from 'react';

import styles from "./styles.module.scss";

export default function Link({ url }) {
  const [copyText, setCopyText] = useState("Copiar");

  const copyUrl = async () => {
    if (url) {
      await navigator.clipboard.writeText(url);
      setCopyText("Copiado!");

      setTimeout(() => {
        setCopyText("Copiar");
      }, 4000);
    }
  };

  return (
    <div className={styles.link}>
      <p><b>Copie o link</b> abaixo e <b>compartilhe</b> com amigos e grupos nas redes sociais!</p>

      <div className={styles.containerLink}>
        <p>{url}</p>

        <span onClick={copyUrl}>{copyText}</span>
      </div>
    </div>
  );
}
