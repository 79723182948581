import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './store/application-store';
import StorageHelper from './utils/helpers/storage-helper';
import ApplicationRouter from './navigation/application-router';
import { updateNetworkState } from './store/action/device-actions';
import * as Authentication from './utils/helpers/authentication-helper';

// Persisting the redux into browser storage
store.subscribe(() => {
  StorageHelper.saveStateToLocalStorage(store.getState());
});

function App() {
  useEffect(() => {
    if (!Authentication.isAuthenticated()) {
      StorageHelper.removeStateStorage();
    }
    window.addEventListener('offline', () => store.dispatch(updateNetworkState(false)));
    window.addEventListener('online', () => store.dispatch(updateNetworkState(true)));
    return () => {
      window.removeEventListener('offline', () => store.dispatch(updateNetworkState(false)));
      window.removeEventListener('online', () => store.dispatch(updateNetworkState(true)));
    };
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <ApplicationRouter />
      </Provider>
    </div>
  );
}

export default App;
