import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { PartnerKindHelper } from "../../utils/helpers/partner-kind-helper";
import "./sidebar-component.scss";
import {
  ArrowRightIcon,
  BookIcon,
  DoubtIcon,
  GraphicIcon,
  HandMoneyIcon,
  HomeIcon,
  LogoutIcon,
  SellerIcon,
  ServiceIcon,
  WhatsappIcon,
  TermsIcon,
  NotificationsIcon,
  SupportIcon,
  LikeIcon,
} from "components/ui/icons/icons";
import routes from "navigation/routes";
import { useSelector } from "react-redux";
import * as MultiplikHelper from "utils/helpers/multiplik-helper";
import { formatTaxId, formatCorporateTaxId } from 'utils/helpers/format-helper';
import BANK_LIST from 'utils/constants/banks';
import InfluencerLevel from "components/influencer-level/influencer-level";

const SidebarComponent = forwardRef(
  ({ loggedIn, logout, isPartner, setClosed }, ref) => {
    const whatsappLink = "https://www.whatsapp.com/channel/0029VaA58Zp5q08i8ElPAG2S?utm_source=area-logada&utm_medium=menu&utm_campaign=canal-zap";
    const history = useHistory();
    const seller = useSelector((state) => state.seller.seller)?.data;
    const bankAccountType = seller?.businessDetails?.bankAccountType;
    const influencerData = useSelector((state) => state?.multiplik?.influencerData);
    const influencerName = seller?.businessDetails?.businessName ? seller?.businessDetails?.businessName : influencerData?.data?.name;
    const influencerTaxId = formatTaxId(influencerData?.data?.documents?.taxId?.number);

    const isCorporateBankAccount = seller?.businessDetails?.bankAccountType == 2;

    const personBankAccount = influencerData?.data?.finance?.primaryBankAccount;
    const businessDetailsBankAccount = seller?.businessDetails?.bankAccount;

    const influencerBankAgency = isCorporateBankAccount ? businessDetailsBankAccount?.branchNumber : personBankAccount?.branchNumber;
    const influencerBankAccount = isCorporateBankAccount ?
      (businessDetailsBankAccount?.number + '-' + businessDetailsBankAccount?.checkDigit) :
      (personBankAccount?.number + '-' + personBankAccount?.checkDigit);
    const influencerBankInfo = BANK_LIST.find((item) => item.code == (isCorporateBankAccount ? businessDetailsBankAccount?.bankCode : personBankAccount?.bankCode));
    const influencerBankName = influencerBankInfo ? influencerBankInfo.name : "Banco Desconhecido";

    const isMultiplikOffline = JSON.parse(sessionStorage.getItem("isMultiplikOffline"));

    //Functions to use with ref
    useImperativeHandle(ref, () => ({
      toggle() {
        const sidebar = document.getElementById("sidebar");
        sidebar.classList.toggle("sidebar-wrapper--active");

        ref.current.isOpen = sidebar.classList.contains(
          "sidebar-wrapper--active",
        );
      },

      close() {
        removeSidebarActiveClass();

        ref.current.isOpen = false;

        setClosed();
      },
    }));

    const removeSidebarActiveClass = () => {
      const sidebar = document.getElementById("sidebar");
      sidebar.classList.remove("sidebar-wrapper--active");
    };

    //hide sidebar on change route
    useEffect(() => {
      const unlisten = history.listen(() => {
        removeSidebarActiveClass();

        ref.current?.close();
      });

      return () => unlisten();
    }, [history, ref]);

    function checkIfPopRegisterIsNotCompleted() {
      const { businessName, businessInvoicing, businessKind, corporateId, businessDetails } = seller?.businessDetails;

      const keysToIgnore = ["complement"];

      const hasNullValueInAddress =
      businessDetails?.businessAddress &&
      Object.keys(businessDetails.businessAddress).some(
        (key) =>
          businessDetails.businessAddress[key] === null && !keysToIgnore.includes(key),
      );

      if (!businessName || businessInvoicing === null || businessKind === null || !corporateId || hasNullValueInAddress) {
        return true;
      }

      return false;
    }

    function goToEditSellerData() {
      if (isMultiplikOffline) {
        if (checkIfPopRegisterIsNotCompleted()) {
          history.push(routes.MULTIPLIK_OFFLINE_POP_REGISTER);
        } else {
          history.push(routes.MULTIPLIK_OFFLINE_POP_EDIT);
        }
      } else {
        history.push(routes.MULTIPLIK_CONFIG);
      }
    }

    const NavItem = ({ text, icon, href, isNew, ...rest }) => {
      return (
        <li className="sidebar__menu-item" {...rest}>
          <NavLink to={href} title={text}>
            {icon}
            <div className="sidebar__menu-item-text-wrapper">
              {isNew && <span className="sidebar__menu-badge">Novo!</span>}
              <div>{text}</div>
            </div>
            <ArrowRightIcon />
          </NavLink>
        </li>
      );
    };

    const ExternalItem = ({ text, icon, href, isNew, ...rest }) => {
      return (
        <li className="sidebar__menu-item" {...rest}>
          <a href={href} target="_blank" rel="noopener noreferrer" title={text}>
            {icon}
            <div className="sidebar__menu-item-text-wrapper">
              {isNew && <span className="sidebar__menu-badge">Novo!</span>}
              <div>{text}</div>
            </div>
            <ArrowRightIcon />
          </a>
        </li>
      );
    };

    if (MultiplikHelper.isMultiplikMember() || seller?.defaultSource === "multiplik.supersim.com.br") {
      return (
        <div id="sidebar" className="sidebar-wrapper">
          <div className="sidebar">
            <ul className="sidebar__menu">
              <div className="sidebar__menu-header">
                <InfluencerLevel
                  name={influencerName}
                  level={seller?.level}
                  influencerTaxId={influencerTaxId}
                  bankAccountType={bankAccountType}
                />

                <div className="mt-4 influencer-details">
                  <div className="influencer-box mt-3">
                    {influencerBankName ? (
                      <p>{influencerBankName}</p>
                    ) : null}

                    {(influencerBankAgency && influencerBankAccount) ? (
                      <p>ag <span>{influencerBankAgency}</span> • cc <span>{influencerBankAccount}</span></p>
                    ) : null}
                    {bankAccountType === 2 ? (
                      <p style={{ color: "#FE690A" }}><strong>Vínculo CNPJ</strong></p>
                    ) : (
                      <p style={{ color: "#FE690A" }}><strong>Vínculo CPF</strong></p>
                    )}
                  </div>
                  <div className="influencer-box">
                    <button className="edit-data-button" onClick={goToEditSellerData}>
                      Meus dados
                    </button>
                  </div>
                </div>
              </div>

              <>
                <NavItem
                  text="Início"
                  icon={<HomeIcon width={40} height={60} hideCircle />}
                  href={routes.MULTIPLIK_HOME}
                />
                <NavItem
                  text="Notificações"
                  icon={<NotificationsIcon width={40} height={60} hideCircle />}
                  href={routes.MULTIPLIK_NOTIFICATIONS}
                />
                <NavItem
                  text="Materiais de apoio"
                  icon={<LikeIcon width={40} height={60} hideCircle />}
                  href={routes.MULTIPLIK_MEDIA_KIT}
                />
                <ExternalItem
                  text="Canal Whatsapp"
                  icon={<WhatsappIcon width={40} height={60} hideCircle />}
                  href={whatsappLink}
                />
                <NavItem
                  text="Termos e Regulamento"
                  icon={<TermsIcon width={40} height={60} hideCircle />}
                  href={routes.MULTIPLIK_RULES}
                />
                <NavItem
                  text="Dúvidas frequentes"
                  icon={<DoubtIcon width={40} height={60} hideCircle />}
                  href={routes.MULTIPLIK_FAQ}
                />
                <NavItem
                  id="logout-button"
                  text="Sair"
                  icon={<LogoutIcon width={40} height={60} hideCircle />}
                  href="#"
                  onClick={logout}
                />
              </>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div id="sidebar" className="sidebar-wrapper">
        <div className="sidebar">
          <ul className="sidebar__menu">
            {isPartner ? (
              <>
                <NavItem
                  text="Conversões"
                  icon={<HandMoneyIcon />}
                  href={routes.PARTNER_CONVERSION}
                />
              </>
            ) : (
              <>
                <NavItem
                  text="Início"
                  icon={<HomeIcon width={40} height={60} hideCircle />}
                  href={routes.HOME}
                />
                <NavItem
                  text="Meus resultados"
                  icon={<GraphicIcon width={40} height={60} hideCircle />}
                  href={routes.NET}
                />
                {PartnerKindHelper.canCreateNewSeller(seller?.businessDetails?.partnerKind) && <NavItem
                  text="Novo vendedor"
                  icon={<SellerIcon width={40} height={60} hideCircle />}
                  href={routes.NET_SELLER_NEW}
                />}
                {PartnerKindHelper.canCreateServices(seller?.businessDetails?.partnerKind) ? (<NavItem
                  text="Atendimento ao Cliente"
                  icon={<ServiceIcon width={40} height={60} hideCircle />}
                  href={routes.SERVICE_PROVISION}
                  isNew
                />) : ''}
                <NavItem
                  text="Venda de empréstimos"
                  icon={<HandMoneyIcon width={40} height={60} hideCircle />}
                  href={routes.LOAN_APPLICATION_START}
                />
                <NavItem
                  text="Materiais de apoio"
                  icon={<BookIcon width={40} height={60} hideCircle />}
                  href={routes.SUPPORT_MATERIALS}
                />
                <NavItem
                  text="Dúvidas frequentes"
                  icon={<DoubtIcon width={40} height={60} />}
                  href={routes.SELLER_FAQ}
                />
              </>
            )}
            <NavItem
              id="logout-button"
              text="Sair"
              icon={<LogoutIcon width={40} height={60} hideCircle />}
              href="#"
              onClick={logout}
            />
          </ul>
        </div>
      </div>
    );
  },
);

SidebarComponent.displayName = "TestComponent";

export default React.memo(SidebarComponent);
