import Cookies from "js-cookie";

export const isAuthenticated = () => {
  const token = Cookies.get("oauth2Token");

  if (token && window.location.search.includes("tempToken")) {
    localStorage.setItem("loggedIn", "true");
  }

  if (token == null) {
    return false;
  }
  const loggedIn = localStorage.getItem("loggedIn");

  return loggedIn && loggedIn === "true";
};
