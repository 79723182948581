import React from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

function NavigationBar({ title, returnRoute, goBackHidden, ...rest }) {
  const history = useHistory();

  const goBack = () => {
    if (returnRoute) {
      history.replace(returnRoute);
    } else {
      history.goBack();
    }
  };

  return (
    <div className={`${styles.navigatonBar}`} {...rest}>
      <Container>
        <div className={styles.content}>
          {!goBackHidden && (
            <i
              className={`fas fa-angle-left ${styles.icon}`}
              onClick={goBack}
            ></i>
          )}

          <h1 className={styles.navigationTitle}>{title}</h1>
        </div>
      </Container>
    </div>
  );
}

export default React.memo(NavigationBar);
