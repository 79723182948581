import * as deviceActions from 'store/action/device-actions';

let initialState = {
  isOnline: true,
};

export default function device(state = initialState, action) {
  switch (action.type) {
    case deviceActions.NETWORK_STATE_ACTION:
      return {
        ...state,
        isOnline: action.payload,
      };

    default:
      return { ...state };
  }
}
