import React from 'react';
import InputMask from 'react-input-mask';
import { Form } from 'react-bootstrap';
import './masked-input.scss';

const MaskedInput = (props) => {
  const validationClass =
    !props.isInvalid && props.touched ? 'is-valid-field' : '';
  const loading = props.loading;
  return (
    <>
      <Form.Control as={InputMask} {...props} className={validationClass}>
        {props.children}
      </Form.Control>
      {loading && <i className="fas fa-spinner fa-pulse fa-fw"></i>}
    </>
  );
};

export default MaskedInput;
