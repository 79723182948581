import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

import AuthenticationService from "service/authentication-service";

import { logout } from "../../store/action/authentication-actions";
import { LogoutIcon, NotificationsIcon } from "components/ui/icons/icons";

import multipliklogo from "assets/images/logo_multiplik.svg";
import SupersimLogo from "assets/images/logo.png";

import SidebarComponent from "../sidebar/sidebar-component";

import history from "../../navigation/history";
import routes from "../../navigation/routes";

import * as PartnerDashboardHelper from "utils/helpers/partner-dashboard-helper";

import "../sidebar/sidebar-component.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function HeaderComponent(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sideBarRef = useRef(null);
  const isAuthenticated = useSelector((state) => state.authentication.isLoggedIn) || (Cookies.get("oauth2Token") != null && localStorage.getItem("loggedIn") === "true");

  useEffect(() => {
    AuthenticationService.startListenerRefreshToken();
  }, []);

  if (PartnerDashboardHelper.detectIfPartner()) {
    useEffect(() => {
      document.title = 'Portal do Parceiro';
    }, []);
  }

  const closeNav = () => {
    setSidebarOpen(false);
  };

  const toggleNavBar = () => {
    sideBarRef.current?.toggle();
    setSidebarOpen(sideBarRef.current?.isOpen);
  };

  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logout()).then(() => {
      if (PartnerDashboardHelper.detectIfPartner())
        history.push(routes.PARTNER);
      else
        history.push(routes.MULTIPLIK_NOTIFICATIONS);

      window.location.reload();
    });
  };

  const navigateToPushNotifications = () => {
    history.push(routes.MULTIPLIK_NOTIFICATIONS);
  };

  const location = useLocation();
  const pathsWithoutLogo = [routes.MULTIPLIK_NEW, routes.MULTIPLIK_BANK];

  const withoutLogo = pathsWithoutLogo.includes(location.pathname);

  return (
    <>
      <SidebarComponent
        ref={sideBarRef}
        logout={logoutUser}
        setClosed={() => closeNav()}
        sidebarOpen={sidebarOpen}
      />
      <Navbar className="background-primary" fixed="top">
        <Navbar.Brand style={{ cursor: "pointer" }}>
          {window.location.href.includes("parceiro") ? (
            <NavLink to={routes.PARTNER}>
              <img src={SupersimLogo} alt="Logo" className="header-logo" width="150" height="48" />
            </NavLink>)
            :
            (<NavLink to={routes.MULTIPLIK_LOGIN}>
              <img src={multipliklogo} alt="Logo" className="header-logo" width="115" height="48" />
            </NavLink>)
          }
        </Navbar.Brand>

        {!withoutLogo && (
          props.showMenu && isAuthenticated && !PartnerDashboardHelper.detectIfPartner() ? (
            <div style={{ display: 'flex' }}>
              <div style={{ cursor: "pointer",
                marginRight: '15px' }} onClick={navigateToPushNotifications}>
                <NotificationsIcon width={40} height={60} hideCircle />
              </div>
              <i
                id="menu"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: sidebarOpen ? '#757575' : '#BDBDBD',
                }}
                className={`${
                  sidebarOpen ? "fas fa-times" : "fas fa-user-circle"
                } menu-hamburguer`}
                onClick={() => toggleNavBar()}>
              </i>
            </div>
          ) : (
            props.showLogoutLogo ? (
              <div style={{ cursor: "pointer" }} onClick={logoutUser}>
                <LogoutIcon color="orange" width={40} height={40} hideCircle/>
              </div>
            ) : null
          )
        )}

      </Navbar>
    </>
  );
}

HeaderComponent.propTypes = {
  filter: PropTypes.object,
  logout: PropTypes.func,
  showMenu: PropTypes.bool,
  showLogoutLogo: PropTypes.bool,
};

HeaderComponent.defaultProps = {
  showMenu: true,
  // if this props is true, it will be displayed the Logout Logo instead of the SideBar.
  showLogoutLogo: false,
};

export default HeaderComponent;
