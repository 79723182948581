const initialState = {
  influencerData: {},
  hideResetPasswordDialog: false,
};

export default function multiplikReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_INFLUENCER_PERSON_DATA":
      return {
        ...state,
        influencerData: action.influencerData,
      };
    case "SET_SHOW_RESET_PASSWORD_MODAL":
      return {
        ...state,
        hideResetPasswordDialog: action.hideResetPasswordDialog,
      };
    default:
      return state;
  }
}
