import * as actions from 'store/action/authentication-actions';

let initialState = {
  user: null,
  oAuth2Token: null,
  isLoggedIn: false,
};

export default function seller(state = initialState, action) {
  switch (action.type) {
    case actions.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        oAuth2Token: action.payload,
        user: action.payload?.user,
        isLoggedIn: true,
      };

    case actions.AUTHENTICATE_FAILURE:
      return {
        ...state,
        oAuth2Token: null,
        user: null,
        isLoggedIn: false,
      };

    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        oAuth2Token: null,
        user: null,
        isLoggedIn: false,
      };

    default:
      return { ...state };
  }
}
