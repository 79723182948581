import React from "react";
import { Button } from "lib-ui-react";
import history from "navigation/history";
import routes from "navigation/routes";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import MultiplikService from "service/multiplik-service";
import * as Authentication from "utils/helpers/authentication-helper";
import StorageHelper from "utils/helpers/storage-helper";
import ValidationHelper from "utils/helpers/validation-helper";
import * as PartnerDashboardHelper from "utils/helpers/partner-dashboard-helper";
import {
  authenticate,
  AUTHENTICATE_SUCCESS, logout,
} from "../../store/action/authentication-actions";
import "./multiplik.scss";
import Cookies from 'js-cookie';

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      username: "",
      password: "",
      formErrors: {
        email: "",
        password: "",
      },
      usernameValid: false,
      passwordValid: false,
      formValid: false,
      messageError: "",
      loggedIn: false,
      title: this.props.title,
      subtitle: this.props.subtitle,
      firstAccess: this.props.firstAccess,
      program: this.props.program,
      recoverPassword: this.props.recoverPassword,
    };

    this.handleUserInput = this.handleUserInput.bind(this);

    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.authenticate = this.authenticate.bind(this);

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    history.push(routes.LOGIN);

    if (Authentication.isAuthenticated()) {
      if (this.state.program === "partner")
        history.push(routes.SUPERSET_DASHBOARD_PARTNER);
      else
        history.push(routes.MULTIPLIK_HOME);
    }
  }

  handleUserInput(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.setState({ ...this.state,
      [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.authenticate();
    }
  }

  validateForm() {
    this.setState({
      ...this.state,
      formValid: this.state.usernameValid && this.state.passwordValid,
    });
  }

  isLocalhost() {
    const regex = /localhost/;
    return regex.test(window.location.href);
  }

  logout() {
    this.props.logout().then(() => {
      if (this.PartnerDashboardHelper.detectIfPartner())
        history.push(routes.PARTNER);
      else
        history.push(routes.LOGIN);

      window.location.reload();
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;
    let { usernameValid } = this.state;
    let { passwordValid } = this.state;

    switch (fieldName) {
    case "username":
      const taxIdValid = ValidationHelper.checkValidity(value, {
        taxId: true,
      });

      const emailValid = ValidationHelper.checkValidity(value, {
        email: true,
      });

      const phoneValid = ValidationHelper.checkValidity(
        this.valueAsPhone(value),
        {
          mobilePhoneNumber: true,
        },
      );

      usernameValid = taxIdValid || emailValid || phoneValid;

      fieldValidationErrors.username = usernameValid
        ? ""
        : " CPF, email ou telefone inválido ";
      break;
    case "password":
      passwordValid = value.length >= 8;

      fieldValidationErrors.password = passwordValid
        ? ""
        : " Senha deve ter no mínimo 8 dígitos";
      break;
    default:
      break;
    }
    this.setState(
      {
        ...this.state,
        passwordValid,
        usernameValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm,
    );
  }

  authenticate(e) {
    e?.preventDefault();

    this.setState({ ...this.state,
      loading: true,
      messageError: "" });

    this.props.authenticate(this.state.username, this.state.password).then(
      (response) => {
        if (response.type === AUTHENTICATE_SUCCESS) {
          this.setState({ ...this.state,
            loading: false });

          MultiplikService.checkMultiplikMember().then(
            (response) => {
              if (response.data) {
                if (PartnerDashboardHelper.detectIfPartner() && !this.isLocalhost()) {
                  this.logout();
                } else {
                  localStorage.setItem("multiplikMember", "true");

                  StorageHelper.removeStateStorage();

                  window.location = routes.MULTIPLIK_HOME;
                }
              } else {
                if (!PartnerDashboardHelper.detectIfPartner() && !this.isLocalhost()) {
                  this.logout();
                } else {
                  localStorage.setItem("multiplikMember", "false");

                  StorageHelper.removeStateStorage();

                  window.location = routes.SUPERSET_DASHBOARD_PARTNER;
                }
              }
            },
            () => {
              routes.goToRoute(routes.MULTIPLIK_NEW);
            },
          );
        } else {
          this.setState({
            ...this.state,
            loading: false,
            messageError: "Usuário ou senha incorreto",
          });
        }
      },
      () => {
        this.setState({
          ...this.state,
          loading: false,
          messageError: "Usuário ou senha incorreto",
        });
      },
    );
  }

  valueAsPhone(value) {
    const token1 = value.slice(0, 2);
    const token2 = value.slice(2, 7);
    const token3 = value.slice(7);

    return `(${token1}) ${token2}-${token3}`;
  }

  render() {
    const label = this.state.program === "partner" ? "E-mail" : "CPF";

    return (
      <div className="multiplik">
        <div className="multiplik-login-container">
          <div className="pb-3 center">
            <h1 style={{ color: "#001D7E",
              fontSize: "2em" }} className="mb0">
              Bem-vindo(a) ao {this.state.title}!
            </h1>
            <h2
              style={{ color: "#001D7E",
                fontSize: "1em",
                fontWeight: 300 }}
              className="mt2"
            >
              {this.state.subtitle}
            </h2>
          </div>
          <div className="multiplik-login-form-container">
            <Form id="loginForm" onKeyDown={this.handleKeyPress}>
              <Form.Group controlId="formBasicLogin">
                <Form.Label>
                  {label}<span style={{ color: "#FD7037" }}>*</span>
                </Form.Label><Form.Control
                  name="username"
                  placeholder={label}
                  type="text"
                  value={this.state.username}
                  onChange={this.handleUserInput}
                  isInvalid={this.state.formErrors.username} />
                <Form.Control.Feedback type="invalid">
                  {this.state.formErrors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  Senha<span style={{ color: "#FD7037" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="ex: 53mXFd91DA"
                  value={this.state.password}
                  onChange={this.handleUserInput}
                  isInvalid={this.state.formErrors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.formErrors.password}
                </Form.Control.Feedback>
              </Form.Group>

              {this.state.messageError && (
                <section id="errors" className="text-center mb2">
                  <div className="text-danger">{this.state.messageError}</div>
                </section>
              )}

              <div className="align-center w-100">
                <Button
                  label={"Entrar"}
                  onClick={this.authenticate}
                  className="pl3 pr3 mt1 mb2 mx-auto col-12"
                  disabled={!this.state.formValid}
                  loading={this.state.loading}
                  primary={false}
                ></Button>
                {this.state.firstAccess && (
                  <Button
                    label={"Primeiro Acesso"}
                    className="pl3 pr3 mt1 mb2 mx-auto col-12 first-access-btn"
                    onClick={() =>
                      routes.goToRoute(
                        routes.REQUEST_CHANGE_PASSWORD,
                        false,
                        false,
                        { program: "multiplik" },
                    )
                    }
                    primary={false}
                  ></Button>
                )}
              </div>
            </Form>
          </div>

          {this.state.recoverPassword && (
            <div
              className="mb2 password-btn center"
              style={{ width: "100%",
                color: "white" }}
            >
              {this.state.program === "partner" ? (
                <a href="https://www.supersim.com.br/entrar/nova-senha">Esqueci minha senha</a>
              ) : (
                <NavLink to={routes.REQUEST_CHANGE_PASSWORD + "?program=" + this.state.program}>
                  Esqueci minha senha
                </NavLink>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

LoginComponent.propTypes = {
  authenticate: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.object,
  firstAccess: PropTypes.bool,
  program: PropTypes.string,
  recoverPassword: PropTypes.bool,
  logout: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  authenticate: (username, password) =>
    dispatch(authenticate(username, password)),
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(LoginComponent);
