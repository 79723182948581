import API from "config/api";

const SupersetService = {
  chartFromDashboardSuperset: (startDate, endDate, pageNumber, pageSize) => {
    return API.get(`/superset/chart?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  chartRowCount: (startDate, endDate) => {
    return API.get(`/superset/chart/row-count?startDate=${startDate}&endDate=${endDate}`);
  },
  chartFromDashboardSupersetCSV: (startDate, endDate) => {
    return API.get(`/superset/chart/csv?startDate=${startDate}&endDate=${endDate}`);
  },
  isCPAorCPLorCPCSPartner: () => API.get(`/superset/partner/type`),
};

export default SupersetService;
