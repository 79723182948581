import React from 'react';

export const OfflineComponent = () => {
  return (
    <section className="component">
      <div className="text-center mt-5">
        <h1 className="text-primary">Ops!</h1>
        <p>Parece que você está offline.</p>
        <small>Verifique sua conexão com a internet e tente novamente.</small>
      </div>
    </section>
  );
};
