import React, { Suspense, lazy } from "react";
import { Route, Router, Switch } from "react-router-dom";

import history from "navigation/history";
import PrivateRoute from "navigation/private-route";
import routes from "navigation/routes";

import Header from "components/header/header";
import MainComponent from "components/main-component";
import LoadingComponent from "components/ui/loading/loading-component";
import NotFound from "pages/not-found";
import MediaKit from "pages/media-kit/media-kit";
import MultiplikHome from "pages/main/multiplik-home";
import MultiplikFaq from "pages/faq/faq";
import MultiplikNotification from "pages/notification/notification";
import MultiplikRules from "pages/rules/rules";
import MultiplikConfig from "pages/config/config";
import MultiplikLandingPage from "pages/landing-page/landing-page";
import partnerDashboard from "pages/superset/partner-dashboard";

import RequestPassword from "pages/reset-password/request-reset-password";
import NewPassword from "pages/reset-password/new-password";
import LoginComponent from "pages/login/login-component";

const MultiplikRegisterOneLazy = lazy(() =>
  import("pages/register/multiplik-register-one"),
);

const MultiplikRegisterTwoLazy = lazy(() =>
  import("pages/register/multiplik-register-two"),
);

const ApplicationRouter = () => {
  return (
    <Router history={history}>
      <Header history={history} />

      <MainComponent>
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            {/* Public routes */}
            <Route
              exact
              path={[routes.ROOT, routes.LOGIN, routes.MULTIPLIK_LOGIN]}
            >
              <LoginComponent
                title="Multiplik"
                subtitle={
                  <>
                    O programa de indicação que <strong>@você</strong> pode
                    <br /> ganhar até R$ 3 MIL em renda extra 👇
                  </>
                }
                firstAccess={true}
                program="multiplik"
                recoverPassword={true}
              />
            </Route>

            <Route exact path={[routes.PARTNER]}>
              <LoginComponent
                title="Parceiros SuperSim"
                program="partner"
                recoverPassword={true}
              />
            </Route>

            <Route
              exact
              path={routes.REQUEST_CHANGE_PASSWORD}
              component={RequestPassword}
            />

            <Route
              exact
              path={routes.CHANGE_PASSWORD}
              component={NewPassword}
            />

            <Route
              exact
              path={routes.MULTIPLIK_NEW}
              component={MultiplikRegisterOneLazy}
            />

            <Route
              path={routes.MULTIPLIK_OFFLINE_LANDING_PAGE}
              component={MultiplikLandingPage}
              exact
            />

            <Route
              path={routes.MULTIPLIK_ONLINE_LANDING_PAGE}
              component={MultiplikLandingPage}
              exact
            />

            {/* Private routes */}
            <PrivateRoute
              exact
              path={routes.MULTIPLIK_HOME}
              component={MultiplikHome}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_NOTIFICATIONS}
              component={MultiplikNotification}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_BANK}
              component={MultiplikRegisterTwoLazy}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_MEDIA_KIT}
              component={MediaKit}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_FAQ}
              component={MultiplikFaq}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_RULES}
              component={MultiplikRules}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_CONFIG}
              component={MultiplikConfig}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_OFFLINE_POP_REGISTER}
              component={MultiplikConfig}
            />

            <PrivateRoute
              exact
              path={routes.MULTIPLIK_OFFLINE_POP_EDIT}
              component={MultiplikConfig}
            />

            <PrivateRoute
              path={routes.SUPERSET_DASHBOARD_PARTNER}
              component={partnerDashboard}
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </MainComponent>
    </Router>
  );
};

export default ApplicationRouter;
