import React, { useState, useEffect } from "react";
import { Button, IconButton } from "lib-ui-react";
import { SpinnerIcon } from "components/ui/icons/icons";
import { DateRangePicker } from "react-dates";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from 'react-notifications';
import ErrorHelper from "utils/helpers/error-helper";
import SupersetService from "service/superset-service";
import moment from "moment";
import "react-dates/initialize";
import "moment/locale/pt-br";
import "./partner-dashboard.scss";
import DateHelper from "utils/helpers/date-helper";

function PartnerTable() {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [partnerType, setPartnerType] = useState();
  const [chart, setChart] = useState([]);
  const [totalRows, setTotalRows] = useState(1);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCsv, setLoadingCsv] = useState(false);

  const [dates, setDates] = useState({
    startDate: moment()
      .subtract(5, "days")
      .set(
        {
          hour: 0,
          minute: 0,
          second: 0,
        }),
    endDate: moment()
      .subtract(1, "days")
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      }),
  });

  const [focusedInput, setFocusedInput] = useState(null);
  useEffect(() => {
    moment.locale("pt-br");

    setLoadingData(true);

    if (partnerType == null) {
      SupersetService.isCPAorCPLorCPCSPartner().then((response) => {
        setPartnerType(response.data);
      });
    }

    async function chartFromDashboardSuperset() {
      const chart = await SupersetService.chartFromDashboardSuperset(
        dates.startDate,
        dates.endDate,
        pageNumber,
        pageSize,
      );

      setChart(chart.data);
      setLoadingData(false);
    }

    async function chartTotalRows() {
      const totalRowsCount = await SupersetService.chartRowCount(dates.startDate, dates.endDate);
      setTotalRows(totalRowsCount.data);
    }
    chartFromDashboardSuperset();
    chartTotalRows();
  }, [partnerType, dates, pageNumber, pageSize]);

  const handleDatesChange = (dates) => {
    setDates(dates);
  };

  function exportToCSV() {
    const startDateCSV = moment(dates.startDate);
    const endDateCSV = moment(dates.endDate);

    setLoadingCsv(true);

    SupersetService.chartFromDashboardSupersetCSV(
      startDateCSV,
      endDateCSV,
    ).then(
      (response) => {
        if (response.data) {
          const today = moment().format("YYYY-MM-DD");
          const blob = new Blob([response.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "extracao_dados_parceiro_" + today + ".csv";
          a.click();
          window.URL.revokeObjectURL(url);
        }

        setLoadingCsv(false);
      },
      (error) => {
        NotificationManager.error(ErrorHelper.getMessage(error), "Erro", 5000);
      }
    );
  }

  return (
    <div className="container mb-2">
      <div className="flex justify-between">
        <div className="flex items-center">
          Mostrar
          <select
            id="lang"
            onChange={(e) => setPageSize(e.target.value)}
            value={pageSize}
            style={{ margin: "10px .5em" }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
          Registros
        </div>
        <div className="flex items-center">
          <DateRangePicker
            startDate={dates.startDate}
            startDateId="your_unique_start_date_id"
            endDate={dates.endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
            numberOfMonths={1}
            displayFormat="DD/MM/YYYY"
            showClearDates={true}
            isOutsideRange={(day) => {
              return (
                day.isBefore(moment().subtract(70, "days")) ||
                day.isAfter(moment().subtract(1, "days"))
              );
            }}
            hideKeyboardShortcutsPanel={true}
            startDatePlaceholderText="Data Inicial"
            endDatePlaceholderText="Data Final"
          />
          {loadingCsv ? (
            <SpinnerIcon
              className="loading ml-2"
              style={{
                width: "32px",
                height: "32px",
                color: "#ffa842",
              }}
            />
          ) : (
            <IconButton
              icon={faDownload}
              className="ml-2"
              backgroundColor="white"
              primary={false}
              size="medium"
              onClick={() => exportToCSV()}
            />
          )}
        </div>
      </div>
      <div className="table-container" style={{marginTop: "20px"}}>
        {partnerType === null || loadingData ? (
          <SpinnerIcon
            className="loading mt-4 mb-4"
            style={{
              width: "32px",
              height: "32px",
              color: "#ffa842",
            }}
          />
        ) : (
          <table style={{ border: "1px solid gainsboro" }}>
            <thead>
              <tr>
                <th>
                  <span className="flex items-center">id</span>
                </th>
                <th>
                  <span className="flex items-center">Data de criação</span>
                </th>
                {partnerType === "CPA" ?
                  <>
                    <th>
                      <span className="flex items-center">Data de emissão</span>
                    </th>
                  </>
                  :
                  partnerType === "CPL" ?
                    <>
                      <th>
                        <span className="flex items-center">Data de aprovação</span>
                      </th>
                    </>
                    :
                    <>
                      <th>
                        <span className="flex items-center">Data de comissão</span>
                      </th>
                    </>
                }
                <th>
                  <span className="flex items-center">Campaign</span>
                </th>
                <th>
                  <span className="flex items-center">Medium</span>
                </th>
                {partnerType === "CPA" && (
                  <>
                    <th>
                      <span className="flex items-center">
                        Produto
                        <span className="info-icon ml-1"></span>
                      </span>
                    </th>
                    <th>
                      <span className="flex items-center">
                        Valor do empréstimo
                        <span className="info-icon ml-1"></span>
                      </span>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {chart.length > 0 ? (
                chart.map((e, i) => (
                  <tr key={i}>
                    <td>{e.id}</td>
                    <td>{DateHelper.getFormattedDate(e.created_at)}</td>
                    {partnerType === "CPA" ?
                      <>
                        <td>{DateHelper.getFormattedDate(e.emmited_at)}</td>
                      </>
                      :
                      partnerType === "CPL" ?
                        <>
                          <td>{DateHelper.getFormattedDate(e.approved_at)}</td>
                        </>
                        :
                        <>
                          <td>{DateHelper.getFormattedDate(e.commissioned_at)}</td>
                        </>
                    }
                    <td>{e.campaign}</td>
                    <td>{e.medium}</td>
                    {partnerType === "CPA" && (
                      <>
                        <td>{e.product}</td>
                        <td>{e.value}</td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    Nenhum dado encontrado
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex full-width justify-end mt-2 items-center">
        <span className="mr-3">Página {pageNumber + 1} de {Math.ceil(totalRows / pageSize)}</span>
        <Button
          label="<"
          size="small"
          primary={false}
          disabled={pageNumber === 0}
          onClick={() => setPageNumber(pageNumber - 1)}
        />
        <Button
          className="ml-2"
          label=">"
          size="small"
          primary={false}
          onClick={() => setPageNumber(pageNumber + 1)}
        />
      </div>
    </div>
  );
}

export default PartnerTable;
