import React from 'react';
import styles from "./styles.module.scss";
import firstIndicationImage from "assets/images/first-indication-modal.png";
import { WhatsappWhiteIcon } from 'components/ui/icons/icons';
import { setEventOnDataLayer } from 'utils/helpers/data-helper';

export default function FirstIndicationModal({
  setShowFirstIndication,
}) {
  function goToChannel() {
    setEventOnDataLayer("mpk_modal_primeira_indicacao");
    window.open("https://whatsapp.com/channel/0029VaA58Zp5q08i8ElPAG2S?utm_source=area-logada&utm_medium=card-onboarding&utm_campaign=canal-zap", "_blank");
    closeModal();
  }

  function closeModal() {
    setShowFirstIndication(false);
  }

  return (
    <div className={styles.container}>
      <img className={styles.headerImage} src={firstIndicationImage} />

      <div className={styles.content}>
        <div>
          <h2>Faça agora sua PRIMEIRA INDICAÇÃO pré-aprovada e concorra R$ 1 MIL.</h2>
          <p><span>Entre já no canal do Zap</span> e saiba como participar.</p>
        </div>

        <button className={styles.buttonFirst} onClick={goToChannel} id="mpk_first_indication">
          <WhatsappWhiteIcon className={styles.customWhatsappIcon} hideCircle />
          Siga o Canal
        </button>
      </div>
    </div>
  );
}
