import React from 'react';
import MetropolesLogo from 'assets/images/metropoles-logo.png';
import PegnLogo from 'assets/images/pegn-logo.png';
import ExameLogo from 'assets/images/exame-logo.png';
import styles from './styles.module.scss';

export default function Partners() {
  return (
    <div className={styles.backgroundPartners}>
      <div className={styles.centerText}>
        <h3>#TáNaMídia</h3>
        <p>...e na boca do povo</p>
      </div>
      <div className={styles.partnersFlexContainer}>
        <div className={styles.partnersBox}>
          <div className={styles.partnerCard}>
            <img src={MetropolesLogo} alt="Logo Metropoles" className={styles.cardImage} width="266" height="86" />
            <div className={styles.cardText}>
              <p>“Empresários podem aumentar lucros entrando em programas de indicações. Multiplik - indique e ganhe gera renda extra para quem indica a Supersim”.</p>
            </div>
          </div>
        </div>
        <div className={styles.partnersBox}>
          <div className={styles.partnerCard}>
            <img src={PegnLogo} alt="Logo Pequenas Empresas e Grandes Negocios" className={styles.cardImage} width="266" height="86" />
            <div className={styles.cardText} >
              <p>“SuperSim concede microcrédito para pessoas físicas com nenhum ou pouco acesso a crédito.”</p>
            </div>
          </div>
        </div>
        <div className={styles.partnersBox}>
          <div className={styles.partnerCard}>
            <img src={ExameLogo} alt="Logo Exame" className={styles.cardImage} width="266" height="86" />
            <div className={styles.cardText }>
              <br/>
              <p>“SuperSim: aporte de R$ 136 milhões com FIDC de investidores “Triple A”.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
