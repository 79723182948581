import axios from 'axios';
import * as Authentication from 'utils/helpers/authentication-helper';
import AuthenticationService from 'service/authentication-service';
import { NotificationManager } from 'react-notifications';
import Cookies from 'js-cookie';

/* CONFIG */
const api = axios.create({
  baseURL: `${
    process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:9090/api' : '/api'
  }`,
  responseType: 'json',
});

/* RESPONSE INTERCEPTOR */
const UNAUTHORIZED = 401;
api.interceptors.response.use(
  async (response) => {
    if (
      Authentication.isAuthenticated() &&
      !AuthenticationService.isRefreshTokenRequest(response) &&
      !AuthenticationService.waitingRefreshToken()
    ) {
      localStorage.setItem('doRefreshToken', '1');
    }
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status &&
      error.response.status === UNAUTHORIZED
    ) {
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('multiplikMember');
      Cookies.remove('oauth2Token');
      Cookies.remove('oauth2RefreshToken');
      Cookies.remove('SESSION');

      NotificationManager.error(
        'Acesso inválido, faça login novamente.',
        'Clique aqui',
        5000,
        () => {
          window.location = '/';
        },
      );
      // if user dont click on button, redirect him
      setTimeout(() => {
        window.location = '/';
      }, 5000);
    }
    return Promise.reject(error);
  },
);

/* REQUEST INTERCEPTOR */
api.interceptors.request.use(
  async (config) => {
    const token = AuthenticationService.getCookie('oauth2Token');
    const oauth2RefreshToken =
      AuthenticationService.getCookie('oauth2RefreshToken');

    config.headers = {
      Accept: 'application/json, text/plain, */*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };
    if (Authentication.isAuthenticated()) {
      config.headers.Cookie = `oauth2Token=${token}; oauth2RefreshToken=${oauth2RefreshToken}`;
    }
    if (Authentication.isAuthenticated()) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
