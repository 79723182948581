import React from 'react';

import Button from 'components/button/button';

import check from '../../assets/images/check.png';

import styles from './styles.module.scss';

function MaterialCard({ materialInformation }) {
  const { title, image, informations, backgroundColor, pdfName, buttonText, url } =
    materialInformation;

  return (
    <div className={styles.card}>
      <div className={styles.halfTop} style={{ background: backgroundColor }}>
        <img src={image} height={132} width={180}></img>
      </div>

      <div className={styles.halfBottom}>
        <h3 className={styles.title}>{title}</h3>
        {informations.map((information, index) => (
          <div className={styles.item} key={index}>
            <img src={check} width={16} height={16}></img>
            <span className={styles.itemDescription}>{information}</span>
          </div>
        ))}
      </div>      
      <a
        className={styles.buttonWrapper}
        href={url || (pdfName && `${window.location.origin}/pdf/${pdfName}`)}
        download={pdfName}
      >
        <Button rounded>{buttonText || "Baixar"}</Button>
      </a>
    </div>
  );
}

export default React.memo(MaterialCard);
