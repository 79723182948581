import React from 'react';
import PropTypes from 'prop-types';
import { NotificationContainer } from 'react-notifications';
import LogService from 'service/log-service';
import store from '../store/application-store';
import { OfflineComponent } from './offline-component/offline-component';
import { findSeller } from '../store/action/seller-actions';
import { connect } from 'react-redux';
import LoadingComponent from './ui/loading/loading-component';

class MainComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: { message: '', path: '' },
      isOnline: true,
    };

    store.subscribe(() => {
      const isOnline = store.getState().deviceReducer.isOnline;
      if (this.state.isOnline !== isOnline) {
        this.setState({ isOnline: isOnline });
      }
    });
  }

  componentDidMount() {
    if (this.props.isLoggedIn && this.props.userId) {
      this.props.findSeller(this.props.userId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isLoggedIn && prevProps.userId !== this.props.userId && this.props.userId) {
      this.props.findSeller(this.props.userId);
    }
  }

  componentDidCatch(error, errorInfo) {
    const e = { message: error.message, path: window.location.pathname };
    this.setState({ ...this.state, hasError: true, error: e });
    LogService.error(`${e.message}, pathname: ${e.path}`);
  }

  render() {
    if (this.state.isOnline === false) {
      return (
        <div className="content">
          <OfflineComponent />
        </div>
      );
    }

    if (this.props.loading) {
      return (
        <div className="content" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <LoadingComponent />
        </div>
      );
    }

    return (
      <div className="content">
        {!this.state.hasError ? (
          this.props.children
        ) : (
          <div
            className="text-center text-danger"
            style={{ marginTop: '100px' }}
          >
            <h6>Erro ao renderizar a página.</h6>
            <small>{this.state.error.message}</small>
            <br />
            <small>{this.state.error.path}</small>
          </div>
        )}
        <NotificationContainer />
      </div>
    );
  }
}

MainComponent.propTypes = {
  filter: PropTypes.object,
  findSeller: PropTypes.func,
  loading: PropTypes.bool,
  userId: PropTypes.number,
  isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.seller.loading,
  userId: state.authentication?.user?.id,
  isLoggedIn: state.authentication.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  findSeller: (sellerId) => dispatch(findSeller(sellerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);
