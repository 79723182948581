import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "lib-ui-react";
import LeadService from "service/lead-service";
import ImpactImage from "assets/images/impact-image.jpg";
import ImpactImageOnline from "assets/images/multiplik-online-image.png";
import MaskedFormControl from "components/ui/form/masked-input/masked-input";
import history from "navigation/history";
import styles from './styles.module.scss';
import routes from "navigation/routes";

export default function Impact() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEmailButtonEnabled, setIsEmailButtonEnabled] = useState(false);
  const [isPhoneNumberButtonEnabled, setIsPhoneButtonEnabled] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const isMultiplikOffline = window.location.pathname.includes("multiplik/offline");

  useEffect(() => {
    const regexEmail = /^[\w\.-]+@[\w\.-]+\.\w+$/;
    setIsEmailButtonEnabled(regexEmail.test(email) && isTermsAccepted);
  }, [email, isTermsAccepted]);

  useEffect(() => {
    const phoneRegex = /^\+?(55)?[-\s]?(?:\(\d{2}\)[-.\s]?)?9\d{4,5}[-.\s]?\d{4}$/;
    setIsPhoneButtonEnabled(phoneRegex.test(phoneNumber));
  }, [phoneNumber]);

  function saveLeadWhenAdvancing () {
    const payload = {
      emailAddress: email,
      phoneNumber: phoneNumber.replace(/\D/g, ''),
    };
    const origin = window.location.host + window.location.pathname;

    LeadService.saveLead(payload, origin);
  }

  const handleContinue = () => {
    if (step === 1) {
      saveLeadWhenAdvancing();
      setStep(2);
    } else if (step === 2) {
      saveLeadWhenAdvancing();
      history.push(
        isMultiplikOffline ?
          routes.MULTIPLIK_NEW + `?pop=true&emailAddress=${window.btoa(email)}&mobilePhoneNumber=${window.btoa(phoneNumber)}` :
          routes.MULTIPLIK_NEW + `?emailAddress=${window.btoa(email)}&mobilePhoneNumber=${window.btoa(phoneNumber)}`,
      );
    }
  };

  const renderForm = () => {
    if (step === 1) {
      return (
        <Form className="pt-2">
          <MaskedFormControl
            name="email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }
            }
          />
          <div className="pt1 pb2 w-100">
            <Button
              label="Continuar"
              className="w-100"
              onClick={handleContinue}
              disabled={!isEmailButtonEnabled}
            />
            <div className="accept-terms-container pt1">
              <input
                type="checkbox"
                id="accept-terms-id"
                name="consents"
                className={styles.marginCheckBox}
                onChange={(e) => setIsTermsAccepted(e.target.checked)}
              />
              <label
                htmlFor="accept-terms-id"
                className={styles.labelCheckBox}
              >
                {isMultiplikOffline ? (
                  <span className={styles.spanCheckBox}
                  >
                    * Ao continuar, concordo em receber emails <br />
                    da SuperSim e com os termos do concurso.
                  </span>
                ) : (
                  <span className={styles.spanCheckBox}
                  >
                    * Ao continuar, concordo  com os <span style={{ color: "#fe7f0f" }}>termos de uso</span> <br /> e em receber e-mails da SuperSim.
                  </span>
                )}

              </label>
            </div>
          </div>
        </Form>
      );
    } else if (step === 2) {
      return (
        <Form className="pt-3">
          <MaskedFormControl
            name="phoneNumber"
            placeholder="Telefone"
            type="tel"
            maskChar={false}
            mask={"(99) 99999-9999"}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <div className="pt1 pb2 w-100">
            <Button
              label="Continuar"
              className="w-100"
              onClick={handleContinue}
              disabled={!isPhoneNumberButtonEnabled}
            />
          </div>
        </Form>
      );
    }
  };

  return (
    <div className={styles.backgroundImpact}>
      <div className={styles.impactFlexContainer}>
        <div className={styles.impactBox}>
          {isMultiplikOffline ? (
            <>
              <strong>
                <span className={styles.colorImpactText}>ponto</span>
                <span className={styles.colorPointText}>.</span>
                <span className={styles.colorImpactText}>comercial</span>
              </strong>
              <span className= {styles.colorImpactText}> que gera</span>
            </>
          ) : (
            <>
              <strong>
                <span className={styles.colorImpactText}>Sua indicação</span>
              </strong>
              <span className= {styles.colorImpactText}> gera</span>
            </>
          )}

          <br/>

          <span className={styles.squareBracketsImpact}>[  </span>
          <h1 className={styles.gradientText}>
            {isMultiplikOffline ? "IMPACTO" : "DINHEIRO"}
          </h1>
          <span className={styles.squareBracketsImpact}> ]</span>

          <br/>

          {isMultiplikOffline ? (
            <>
              <span className={styles.gradientText}><strong>Saia na frente!</strong></span> Fidelize clientes da sua
              região e ofereça a solução financeira líder em aprovação de cŕedito no Brasil!
            </>
          ) : (
            <>
              <span className={styles.gradientText}><strong>Cadastre-se já no Multiplik!</strong></span> Indique e ganhe
              dinheiro sem sair de casa. Receba recompensas por indicações <i>pré-aprovadas*</i>
            </>
          )}

          <div>
            <div className={styles.impactCard}>
              <p className={styles.gradientText}>
                <span>Faça <strong>até R$5MIL</strong> em renda extra!</span><br />
              </p>

              {isMultiplikOffline ? (
                <p>Preencha os dados abaixo e ganhe um <strong>material especial</strong> ao final 👇</p>
              ) : (
                <p>Preencha os dados abaixo e ganhe ainda hoje na sua <strong>primeira indicação 👇</strong></p>
              )}
              {renderForm()}
            </div>
          </div>
        </div>

        <div className={styles.impactBox}>
          <img src={isMultiplikOffline ? ImpactImage : ImpactImageOnline} className={isMultiplikOffline ? styles.impactImage : styles.impactImageOnline} />
        </div>
      </div>
    </div>
  );
}
