import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

import UtilHelper from "utils/helpers/util-helper";

import styles from "./styles.module.scss";

export function Legends({ getStatusBullets }) {
  return (
    <div
      className="text-left p-4"
      style={{ minHeight: "328px", color: "#2A2B35" }}
    >
      <p className="mb-4" style={{ fontSize: "16px" }}>
        <b>Status do indicado</b> varia de acordo com o avanço nas etapas de
        solicitação do empréstimo:
      </p>

      <div className="mb-2">
        {getStatusBullets({ saleDetails: { paidAt: true } })}
        <span className="ml-2">
          <b>Pago!</b> - indicação recompensada
        </span>
      </div>

      <div className="mb-2 d-flex">
        <div className={styles.statusDot} style={{ background: "#00BA88" }} />
        <span className="ml-2">
          <b>Validado</b> - apto à receber recompensa
        </span>
      </div>

      <div className="mb-2">
        {getStatusBullets({ saleDetails: {} })}
        <span className="ml-2">
          <b>Pendente</b> - ainda não avançou a etapa
        </span>
      </div>

      <div className="mb-2">
        {getStatusBullets({ saleDetails: { loanCancelledAt: true } })}
        <span className="ml-2">
          <b>Cancelado</b> - cancelou solicitação
        </span>
      </div>

      <div>
        {getStatusBullets({ saleDetails: { declined: true } })}
        <span className="ml-2">
          <b>Reprovado</b> - solicitação sem sucesso
        </span>
      </div>
    </div>
  );
}

export function ClickModal() {
  return (
    <div
      style={{ minHeight: "318px", color: "#2A2B35" }}
      className="text-left p-4"
    >
      <p className="mb-4" style={{ fontSize: "16px" }}>
        <b>Cliques</b> - o indicador refere-se à quantidade total de cliques no
        seu link de indicação.
      </p>

      <hr
        style={{ borderColor: "#E0E0E0", borderWidth: "1px", width: "100%" }}
      />

      <div className="mb-2">
        <FontAwesomeIcon icon={faLightbulb} />
        <strong> Dica!</strong>
      </div>

      <div className="mb-2">
        <b>1.</b>
        <span className="ml-2">
          {" "}
          Ao compartilhar, certifique-se que a pessoa indicada esteja utilizando
          o seu link! Só serão contabilizadas indicações oriundas do seu link
          individual.
        </span>
      </div>

      <div className="mb-2">
        <b>2.</b>
        <span className="ml-2">
          {" "}
          Ao utilizar um encurtador de link <i>ex: bitly</i>, o indicador poderá
          perder o rastreio e não contabilizar esse registro.
        </span>
      </div>
    </div>
  );
}

export function SolicitationsModal() {
  return (
    <div
      style={{ minHeight: "205px", color: "#2A2B35" }}
      className="text-left p-4"
    >
      <p className="mb-4" style={{ fontSize: "16px" }}>
        <b>Solicitações</b> - o indicador refere-se à quantidade total de
        pessoas indicadas que iniciaram solicitação de empréstimo na SuperSim.
      </p>

      <hr
        style={{ borderColor: "#E0E0E0", borderWidth: "1px", width: "100%" }}
      />

      <div className="mb-2">
        <FontAwesomeIcon icon={faLightbulb} />
        <strong> Dica!</strong>
      </div>

      <div className="mb-2">
        <span>
          É uma ótima referência, pois demonstra interesse da pessoa indicada!
          Além de clicar no seu link, o usuário deixou os dados iniciais e
          avançou uma importante etapa.
        </span>
      </div>
    </div>
  );
}

export function PreApprovedModal() {
  return (
    <div
      style={{ minHeight: "205px", color: "#2A2B35" }}
      className="text-left p-4"
    >
      <p className="mb-4" style={{ fontSize: "16px" }}>
        <b>Pré-aprovados</b> - é o principal indicador.
      </p>

      <hr
        style={{ borderColor: "#E0E0E0", borderWidth: "1px", width: "100%" }}
      />

      <div className="mb-2">
        <FontAwesomeIcon icon={faLightbulb} />
        <strong> Dica!</strong>
      </div>

      <div className="mb-2">
        <b>1.</b>
        <span className="ml-2">
          {" "}
          Ao compartilhar, certifique-se que a pessoa indicada esteja utilizando
          o seu link! Só serão contabilizadas indicações oriundas do seu link
          individual.
        </span>
      </div>

      <div className="mb-2">
        <b>2.</b>
        <span className="ml-2">
          {" "}
          Ao utilizar um encurtador de link <i>ex: bitly</i>, o indicador poderá
          perder o rastreio e não contabilizar esse registro.
        </span>
      </div>
    </div>
  );
}

export function BalanceModal({ performance }) {
  return (
    <div
      style={{ minHeight: "205px", color: "#2A2B35" }}
      className="text-left p-4"
    >
      <div className="mb-3">
        ✅ Saldo disponível:{" "}
        <strong>
          {UtilHelper.formatNumberToCurrency(performance?.availableReward)}
        </strong>
      </div>
      <div className="mt-3 mb-2">
        <p style={{ color: "#757575" }}>
          Valor do seu saldo atual. Refere-se as recompensas conquistadas por
          indicações que estão pendentes de depósito.
        </p>
      </div>

      <hr
        style={{ borderColor: "#E0E0E0", borderWidth: "1px", width: "100%" }}
      />

      <div className="mb-3">
        💰 Recompensas (total):{" "}
        <strong>
          {UtilHelper.formatNumberToCurrency(performance?.historyReward)}
        </strong>
      </div>

      <div className="mt-3 mb-2">
        <p style={{ color: "#757575" }}>
          Valor total de todas as recompensas conquistadas. Refere-se a todas
          indicações desde o o início do programa.
        </p>
      </div>

      <hr
        style={{ borderColor: "#E0E0E0", borderWidth: "1px", width: "100%" }}
      />

      <div>
        <p>
          <b>Pagamento de comissões:</b>
          <br /> Ocorre toda semana, às sextas-feiras.
        </p>
      </div>
    </div>
  );
}

export function InvitationsModal() {
  return (
    <div
      style={{ minHeight: "295px", fontSize: 16, color: "#2A2B35" }}
      className="text-left p-4"
    >
      <p>
        O que significa <b>“Pedidos de empréstimo”?</b>
      </p>

      <p>
        É a quantidade de empréstimos iniciados através do seu link de
        indicação.
      </p>

      <p>
        Qualquer pessoa que <b>clicou no seu link</b> e preencheu os dados de{" "}
        <b>e-mail, CPF e telefone</b> é considerado um pedido de empréstimo.
      </p>
    </div>
  );
}

export function ApprovedInvitationsModal() {
  return (
    <div
      style={{ minHeight: "295px", fontSize: 16, color: "#2A2B35" }}
      className="text-left p-4"
    >
      <p>
        O que significa <b>“Pedidos aceitos”</b>?
      </p>

      <p>
        Refere-se ao número de pessoas que foram validadas na análise de crédito
        da empresa com sucesso.
      </p>
    </div>
  );
}
