import React, { createContext, useContext, useEffect, useState } from 'react';
import MultiplikService from "service/multiplik-service";

const CopyCodeContext = createContext();

export const CopyCodeProvider = ({ children }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [url, setUrl] = useState('');

  const copyCodeFunction = async (url) => {
    if (url) {
      // Copy the url to clipboard.
      await navigator.clipboard.writeText(url);

      // Set copied to true.
      setIsCopied(true);

      // Wait 4 seconds and set copied back to false.
      setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    }
  };

  const fetchInvitationLink = async () => {
    const invitationLink = await MultiplikService.getInvitationLink();
    setUrl(invitationLink?.data?.shortLink);
    
    return invitationLink?.data?.shortLink;
    };

    useEffect(() => {
        fetchInvitationLink();
    }, []);

  return (
    <CopyCodeContext.Provider value={{ copyCodeFunction, isCopied, url, fetchInvitationLink }}>
      {children}
    </CopyCodeContext.Provider>
  );
};

export const useCopyCode = () => useContext(CopyCodeContext);