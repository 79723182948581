/**
 * Set the event on dataLayer.
 */
export function setEventOnDataLayer(event, additionalFields) {
  window.dataLayer = window.dataLayer || [];

  if (!window.dataLayer.some((item) => item.event === event)) {
    window.dataLayer?.push({
      event,
      ...additionalFields,
    });
  }
}
