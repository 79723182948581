export const businessKind = [
  {
    value: 9,
    label: "Açougues",
  },
  {
    value: 1,
    label: "Armarinhos",
  },
  {
    value: 8,
    label: "Farmácias",
  },
  {
    value: 4,
    label: "Loja de bijuterias",
  },
  {
    value: 10,
    label: "Lojas de brinquedos",
  },
  {
    value: 11,
    label: "Lojas de calçados",
  },
  {
    value: 12,
    label: "Lojas de departamento",
  },
  {
    value: 5,
    label: "Lojas de eletrônicos e acessórios",
  },
  {
    value: 13,
    label: "Lojas de ferragens e ferramentas",
  },
  {
    value: 14,
    label: "Lojas de material elétrico",
  },
  {
    value: 15,
    label: "Lojas de produtos agropecuários",
  },
  {
    value: 16,
    label: "Lojas de tintas",
  },
  {
    value: 3,
    label: "Lojas de vestuário e confecções",
  },
  {
    value: 2,
    label: "Magazines",
  },
  {
    value: 16,
    label: "Materiais de contrução",
  },
  {
    value: 1,
    label: "Minimercados",
  },
  {
    value: 17,
    label: "Móveis e eletrodomésticos",
  },
  {
    value: 18,
    label: "Padarias",
  },
  {
    value: 19,
    label: "Produtos de perfumaria e cosméticos",
  },
  {
    value: 1,
    label: "Sacolão/hortifrutigranjeiras",
  },
  {
    value: 1,
    label: "Supermercados",
  },
  {
    value: 20,
    label: "Tecnologia",
  },
  {
    value: 100,
    label: "Outros",
  },
];

export const invoicing = [
  {
    value: 0,
    label: "Iniciando vendas",
  },
  {
    value: 1,
    label: "Abaixo de 50 mil",
  },
  {
    value: 2,
    label: "Entre 50 mil e 100 mil",
  },
  {
    value: 3,
    label: "Entre 100 mil e 300 mil",
  },
  {
    value: 4,
    label: "Entre 300 mil e 999 mil",
  },
  {
    value: 5,
    label: "Entre 999 mil e 5 milhões",
  },
  {
    value: 6,
    label: "Acima de 5 milhões",
  },
]