import React from "react";
import Button from "components/button/button";
import styles from "components/ui/notification/styles.module.scss";
import { NotificationManager } from "react-notifications";
import { useCopyCode } from "context/CopyCodeContext";

export default function PushNotificationPixWeekModal({
  setShowPixWeekModal,
  ImageHeaderPixWeek,
}) {

  const { copyCodeFunction, url } = useCopyCode();

  const copyLinkButtonClick = async () => {
    if(copyCodeFunction && url) {
      await copyCodeFunction(url);

      NotificationManager.success(
        "Link de compartilhamento copiado com sucesso",
        "Link copiado",
        3000,
      );
    }

    setShowPixWeekModal(false);
  }

  return (
    <div className={styles.container} style={{ marginTop: '30px' }}>
      <>
        <img className={styles.headerImage} src={ImageHeaderPixWeek} alt="Black Friday" />

        <div className={styles.content}>
          <div>
            <h3>{'FAÇA INDICAÇÕES! GARANTA SEU DINHEIRO DIARIAMENTE'}</h3>

            <p><span>Participe da semana do Pix!</span></p>
            <p>Exclusivamente essa semana teremos <strong className={styles.strongColor}>5 rodadas</strong> de pagamentos!</p>
            <p>Compartilhe seu link e não fique de fora!</p>
          </div>

          <Button onClick={copyLinkButtonClick}>
            Copiar link
          </Button>

        </div>
      </>
    </div>
  );
}
