import React, { useState, useEffect } from 'react';

import moment from 'moment';

import Filter from './filter';
import Performance from './performance';
import Balance from './balance';
import Indications from './indications';

import { SpinnerIcon } from "components/ui/icons/icons";

import MultiplikService from 'service/multiplik-service';

import styles from "./styles.module.scss";

export default function Status({
  performance, dateFrom, dateTo, seller, setDateTo, setDateFrom,
}) {
  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [invitations, setInvitations] = useState();
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    getInvitations();
  }, [pageSize, dateFrom, dateTo]);

  const getInvitations = async () => {
    setLoadingInvitations(true);

    let filters = {
      page: 0,
      pageSize,
      dateStart: moment(dateFrom).format("DD/MM/yyyy"),
      dateEnd: moment(dateTo).format("DD/MM/yyyy"),
    };
    let invitations = await MultiplikService.findSellerSales(filters);

    setInvitations(invitations.data.content);
    setLoadingInvitations(false);
  };

  if (!invitations || !performance) {
    return (
      <div className={styles.loading}>
        <SpinnerIcon
          className="loading"
          style={{
            width: "35px",
            height: "35px",
            color: "#ffa842",
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Filter setDateFrom={setDateFrom} setDateTo={setDateTo} />

      <Performance performance={performance} seller={seller} />

      <Indications
        invitations={invitations}
        setPageSize={setPageSize}
        pageSize={pageSize}
        loadingInvitations={loadingInvitations}
        performance={performance}
      />
    </div>
  );
}
