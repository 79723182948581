import AmigoIconeImage from "assets/images/amigo-icone.png";
import ProdutoIconeImage from "assets/images/produto-icone.png";
import FaturamentoIconeImage from "assets/images/faturamento-icone.png";
import BonusIconeImage from "assets/images/bonus-icone.png";
import MovimentoIconeImage from "assets/images/movimento-icone.png";
import VisibilidadeIconImagem from "assets/images/visibilidade-icone.png";
import ControleIconeImage from "assets/images/controle-icone.png";
import SuporteIconeImage from "assets/images/suporte-icone.png";

const isMultiplikOffline = window.location.pathname.includes("multiplik/offline");

export const differentials = [
  {
    id: 1,
    icon: AmigoIconeImage,
    title: isMultiplikOffline ? "amigo" : "produto",
    description: isMultiplikOffline ? "Seja lembrado no seu bairro e torne-se referência" : "Indique o empréstimo pessoal 100% online ",
  },
  {
    id: 2,
    icon: ProdutoIconeImage,
    title: isMultiplikOffline ? "produto" : "recompensa",
    description: isMultiplikOffline ? "Indique o empréstimo pessoal 100% online" : "Ganhe dinheiro via Pix",
  },
  {
    id: 3,
    icon: FaturamentoIconeImage,
    title: isMultiplikOffline ? "faturamento" : "compartilhe",
    description: isMultiplikOffline ? "Ganhe dinheiro por indicação de empréstimo" : "Cadastre-se e receba seu link de indicação",
  },
  {
    id: 4,
    icon: BonusIconeImage,
    title: isMultiplikOffline ? "bônus mensal" : "plataforma",
    description: isMultiplikOffline ? "Ao atingir metas você turbina suas recompensas" : "Acesse grátis e acompanhe seus resultados",
  },
  {
    id: 5,
    icon: MovimentoIconeImage,
    title: isMultiplikOffline ? "movimento" : "indicações",
    description: isMultiplikOffline ? "Aumento no fluxo de clientes no seu estabelecimento" : "Veja o avanço das pessoas indicadas por você",
  },
  {
    id: 6,
    icon: VisibilidadeIconImagem,
    title: isMultiplikOffline ? "visibilidade" : "bônus",
    description: isMultiplikOffline ? "Material exclusivo para presença online e offline" : "Participe de desafios e ganhe recompensa extra",
  },
  {
    id: 7,
    icon: ControleIconeImage,
    title: isMultiplikOffline ? "controle" : "Participe de desafios e ganhe recompensa extra",
    description: isMultiplikOffline ? "Acompanhe sua evolução no painel Multiplik" : "",
  },
  {
    id: 8,
    icon: SuporteIconeImage,
    title: isMultiplikOffline ? "suporte" : "",
    description: isMultiplikOffline ? "Conte com nosso time de especialistas" : "",
  },
];
