import React from 'react';
import "./notification-style.css";

const NotificationWrapperComponent = (props) => {
  const style = {
    display: "grid",
    justifyContent: "center",
    gap: "5px",
  };

  return (
    <div style={style}>
      {props.updateCommissionNotification}
      {props.onBoardingNotification}
    </div>
  );
};

export default NotificationWrapperComponent;
