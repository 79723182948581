export const isMultiplikMember = () => {
  const cookie = localStorage.getItem("multiplikMember");

  if (cookie == null) {
    return false;
  }

  const isMultiplikMember = localStorage.getItem("multiplikMember");

  return isMultiplikMember && isMultiplikMember === "true";
};
