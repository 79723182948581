import * as sellerActions from "store/action/seller-actions";
import * as authActions from "store/action/authentication-actions";

let initialState = {
  seller: null,
  person: null,
  totals: null,
  serviceTotals: null,
  loading: false,
};

export default function seller(state = initialState, action) {
  switch (action.type) {
  case authActions.LOGOUT_SUCCESS:
    return {
      ...state,
      seller: null,
      person: null,
      totals: null,
      serviceTotals: null,
      loading: false,
    };

  case sellerActions.FIND_SELLER_SUCCESS:
    return {
      ...state,
      person: action.payload?.person,
      seller: action.payload?.seller,
      totals: action.payload?.totals,
      serviceTotals: action.payload?.serviceTotals,
      loading: false,
    };

  case sellerActions.FIND_SELLER_FAILURE:
    return {
      ...state,
      loading: false,
    };

  case sellerActions.FIND_SELLER:
    return {
      ...state,
      loading: true,
    };

  case "SET_SELLER_DATA":
    return {
      ...state,
      seller: action.seller,
    };
  default:
    return { ...state };
  }
}
