import ImageStep1 from "../../assets/images/onboarding_01.png";
import ImageStep2 from "../../assets/images/onboarding_02.jpg";
import ImageStep3 from "../../assets/images/onboarding_03.jpg";
import ImageStep4 from "../../assets/images/onboarding_04.jpg";

export const data = [
  {
    id: 1,
    image: ImageStep1,
    title: "Seja bem-vindo(a)! Fui escolhida para ajudar você",
    description: (
      <>
        Prazer, eu sou a <i style={{ color: "#FE690A" }}>Ana - Multiplikadora.</i> Conte comigo para bombar suas indicações! <br /><br />
        <b>Vou responder algumas perguntas 👉</b>
      </>
    ),
  },
  {
    id: 2,
    image: ImageStep2,
    title: "Pré-aprovado = recompensa",
    description: (
      <>
        Essa é fácil! Ao iniciar a solicitação, a pessoa indicada precisa obter sucesso na etapa de validação de crédito. <br /><br />
        <b>A validação ocorre após a telinha de assinatura de contrato 👉</b>
      </>
    ),
  },
  {
    id: 3,
    image: ImageStep3,
    title: "Receba semanalmente",
    description: (
      <>
        Realizamos rodadas de pagamento toda semana. Evite falhas! Verifique se os seus dados bancários estão corretos. <br /><br />
        <b>A conta bancária deve estar de acordo com a titularidade da pessoa cadastrada  👉</b>
      </>
    ),
  },
  {
    id: 4,
    image: ImageStep4,
    title: "Siga nosso canal do Zap!",
    description: (
      <>
        Eu e minha equipe enviaremos <b style={{ color: "#FE690A" }}>dicas valiosas</b> para você conseguir dinheiro rápido. <br /><br />
        <b>Siga e fique por dentro das novidades ✨</b>
      </>
    ),
  },
];
