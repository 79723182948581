import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/button/button";
import MaskedFormControl from "components/ui/form/masked-input/masked-input";
import Header from './header';
import AuthenticationService from "service/authentication-service";
import UrlHelper from "utils/helpers/url-helper";
import styles from "./styles.module.scss";

export default function RequestPasswordComponent({ title }) {
  const [taxId, setTaxId] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [program, setProgram] = useState("program");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const programParam = UrlHelper.getQueryParam("program");

  const influencerData = useSelector(
    (state) => state?.multiplik?.influencerData,
  );

  const maskedEmailAddress =
    influencerData?.data?.contacts?.primaryEmailAddress?.address.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, "*") + c,
    );

  const isRequestResetPasswordPage = window.location.pathname.includes(
    "login/solicitar-nova-senha",
  );

  function onChangeTaxId(event) {
    if (error) {
      setError(null);
    }

    setTaxId(event.target.value);
  }

  function requestPassword() {
    if (requestSuccess) {
      dispatch({
        type: "SET_SHOW_RESET_PASSWORD_MODAL",
        hideResetPasswordDialog: true,
      });

      if (isRequestResetPasswordPage) {
        history.push(program == "multiplik" ? "/multiplik/entrar" : "/");
      }

      return;
    }

    setLoading(true);

    setError(null);

    const unformattedTaxId = taxId.replace(/\D/g, "");
    AuthenticationService.requestChangePassword(unformattedTaxId, programParam)
      .then(() => {
        setRequestSuccess(true);
      })
      .catch(() => {
        setError(
          "Ocorreu um erro, verifique se o CPF é válido e tente novamente",
        );
      })
      .finally(() => {
        setLoading(false);

        setProgram(programParam);
      });
  }

  return (
    <>
      {isRequestResetPasswordPage ? (
        <Header
          title="RECUPERAÇÃO DE SENHA"
          goBack={programParam === "multiplik" ? "/multiplik/entrar" : "/"}
        />
      ) : null}

      <div
        className={`${styles.content} d-flex flex-column justify-content-between p-3`}
      >
        <div>
          {requestSuccess ? (
            <>
              <p className="mb-2">
                {maskedEmailAddress ? (
                  <b>
                    Enviamos um link para o email {maskedEmailAddress} para você
                    redefinir a senha.
                  </b>
                ) : (
                  <b>
                    Enviamos um link para o seu email para você redefinir a
                    senha.
                  </b>
                )}
              </p>

              <p className="mb-4">
                Verifique a caixa de spam e lixo eletrônico
              </p>
            </>
          ) : (
            <p className="mb-4">
              {title || (
                <>
                  Por favor, insira seu <b>CPF</b>
                </>
              )}
            </p>
          )}

          {!requestSuccess && (
            <Form.Group>
              <MaskedFormControl
                name="taxId"
                placeholder="Digite aqui seu CPF"
                type="text"
                maskChar={false}
                mask="999.999.999-99"
                value={taxId}
                onChange={onChangeTaxId}
                isInvalid={error}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </div>

        <Button onClick={requestPassword} loading={loading}>
          {requestSuccess ? "Voltar" : "Confirmar"}
        </Button>
      </div>
    </>
  );
}
