import React from 'react';
import Wrapper from 'components/wrapper/wrapper';
import TermsOfUse from 'components/terms-of-use/terms-of-use';

function MultiplikRules() {
  return (
    <Wrapper navTitle="Regulamento" withNavbar>
      <TermsOfUse />
    </Wrapper>
  );
}

export default MultiplikRules;
