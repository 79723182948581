import React from "react";
import { Redirect, Route } from "react-router-dom";
import * as Authentication from "utils/helpers/authentication-helper";
import routes from "navigation/routes";

const PrivateRoute = ({ ...rest }) => {
  if (!Authentication.isAuthenticated()) {
    return <Redirect to={routes.MULTIPLIK_LOGIN} />;
  }

  return (
    <Route
      {...rest}
      render={() => <Redirect to={routes.MULTIPLIK_HOME} />}
    />
  );
};

export default PrivateRoute;
