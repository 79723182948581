import React from 'react';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import styles from './styles.module.scss';

export default function Header({ title, goBack }) {
  return (
    <div className={styles.header}>
      <NavLink
        to={goBack}
        type="button"
      >
        <FontAwesomeIcon
          color="#fff"
          icon={faChevronLeft}
          className={styles.backIcon}
        />
      </NavLink>

      <p>{title}</p>

      <div />
    </div>
  );
}
