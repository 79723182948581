import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./styles.module.scss";

function Button({ rounded, loading, children, ...rest }) {
  return (
    <button
      className={`${styles.button} ${rounded && styles.buttonRounded}`}
      disabled={loading}
      {...rest}
    >
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        children
      )}
    </button>
  );
}

export default React.memo(Button);
