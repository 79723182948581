import sellerManual from '../../assets/images/support-materials/support-points-manual.png';

export const mediaKit = [
  {
    id: 1,
    image: sellerManual,
    title: 'Material de Apoio - SuperSim',
    backgroundColor: '#ED2E45',
    informations: [
      'Baixe imagens para compartilhar com as pessoas.',
    ],
    url:'https://drive.google.com/drive/folders/16a5IWnB8B5eRldUdK_hllHg2a9H8mlow',
    buttonText: 'Baixar',
  },
];
