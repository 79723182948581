import React from 'react';
import { differentials } from "./data";
import styles from './styles.module.scss';

export default function Differentials() {
  const isMultiplikOffline = window.location.pathname.includes("multiplik/offline");

  return (
    <div className={styles.backgroundDifferentials}>
      <div className={styles.centerText}>
        <h8 className={styles.diferentialsFormatText}>Diferenciais</h8>

        <h2>Seja um Multiplikador</h2>

        <h7>{isMultiplikOffline ? "Abra as portas para novas oportunidades" : "Veja como funciona:"}</h7>
      </div>

      <div className={styles.differentialsFlexContainer}>
        {differentials.map((item) => (
          <div
            className= {isMultiplikOffline ? styles.differentialsItemsBox : styles.differentialsItemsBoxOnline}
            key={item.title}
            style={(item.id === 7 || item.id === 8) && !isMultiplikOffline ? { display: "none" } : null}
          >
            <div>
              <img src={item.icon} alt="Amigo" className={styles.benefitItemImage }/>
            </div>
            <h4 className={styles.fontSizeFold}><span className={styles.spanDifferentialsColor}>.</span> {item.title}</h4>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
