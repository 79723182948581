import API from "config/api";

const SellerService = {
  searchSeller: (referrerPersonId) =>
    API.get(`/seller/search?referrerPersonId=${referrerPersonId}`),

  findSeller: (id) => API.get(`/seller/${id}`),

  findSellerURL: (id) => API.get(`/seller/${id}/get_link`),

  createSeller: (seller) => {
    const data = new FormData();

    for (const key in seller) {
      data.append(key, seller[key]);
    }

    return API.post("/seller", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "SuperSim-Persistent-Log": JSON.stringify({
          device: {
            kind: 1,
            code: null,
          },
        }),
      },
    });
  },

  uploadSellers: (formData) => {
    return API.post("/seller/csv", formData, {
      headers: {
        "Content-Type": "application/json",
        "SuperSim-Persistent-Log": JSON.stringify({
          device: {
            kind: 1,
            code: null,
          },
        }),
      },
    });
  },

  updateSeller: (id, seller) => {
    const data = new FormData();

    for (const key in seller) {
      data.append(key, seller[key]);
    }

    return API.put(`/seller/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  findSellerLoans: (filters) => {
    return API.get(
      `/seller/${filters.sellerId}/loan/all?page=${filters.page}&pageSize=${filters.pageSize}&from=${filters.dateStart}&to=${filters.dateEnd}`,
    );
  },

  findSellerServices: (filters) => {
    return API.get(
      `/seller/${filters.sellerId}/service-provision/all?page=${filters.page}&pageSize=${filters.pageSize}&from=${filters.dateStart}&to=${filters.dateEnd}`,
    );
  },

  getSellerLoansCsv: (id) =>
    API.get(`/seller/${id}/loan/csv`, { headers: { Accept: "text/csv" } }),

  getContract: (id) =>
    API.get(`/seller/${id}/contract`, { responseType: "html" }),

  getSellerQRCode: (id) =>
    API.get(`/seller/${id}/sellerQRCode`, { responseType: "image" }),

  /**
   * Finds a loan by taxId.
   * @param {string} taxId - The tax id.
   */
  findLoanByTaxId: (taxId) => API.get(`/seller/${taxId}/find-by-taxid`),
  findSellerDetailsByProgram: (id, program) => API.get(`/seller/details/${id}?program=${program}`),
  setMultiplikSellerDetails: (id, details) => API.put(`/seller/details/multiplik-seller/${id}`, details, {
    headers: {
      'Content-Type': 'application/json',
    },
  }),
};

export default SellerService;
