import { combineReducers } from 'redux';
import applicationReducer from 'store/reducer/application-reducer';
import deviceReducer from 'store/reducer/device-reducer';
import authenticationReducer from 'store/reducer/authentication-reducer';
import sellerReducer from 'store/reducer/seller-reducer';
import multiplikReducer from 'store/reducer/multiplik-reducer';

export default combineReducers({
  applicationReducer,
  deviceReducer,
  seller: sellerReducer,
  authentication: authenticationReducer,
  multiplik: multiplikReducer,
});
