import React from "react";
import { Form, Spinner } from "react-bootstrap";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AuthenticationService from "service/authentication-service";
import routes from "navigation/routes";
import Header from './header';
import Button from 'components/button/button';
import UrlHelper from "utils/helpers/url-helper";
import styles from './styles.module.scss';

class NewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: UrlHelper?.getQueryParam("username"),
      validationCode: UrlHelper?.getQueryParam("validationCode"),
      loading: false,
      newPassword: "",
      repeatPassword: "",
      messageError: "",
      loggedIn: false,
      showPassword: {
        firstInput: false,
        secondInput: false,
      },
      programUrlParam: UrlHelper.getQueryParam("program"),
    };
  }

  onNewPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  onRepeatPasswordChange = (event) => {
    this.setState({ repeatPassword: event.target.value });
  };

  changePassword = () => {
    const { username, validationCode, newPassword, repeatPassword } =
      this.state;
    this.setState({ messageError: "",
      loading: true });

    if (newPassword !== repeatPassword) {
      this.setState({
        messageError: "As duas senhas devem ser iguais.",
        loading: false,
        username: UrlHelper?.getQueryParam("username"),
        validationCode: UrlHelper?.getQueryParam("validationCode"),
      });
    } else {
      AuthenticationService.changePassword(
        username,
        validationCode,
        newPassword,
      ).then((response) => {
        if (response.status === 200) {
          NotificationManager.success("Senha alterada com sucesso");

          let program = UrlHelper.getQueryParam("program");
          window.location =
            program === "multiplik" ? routes.MULTIPLIK_LOGIN : routes.LOGIN;
        } else {
          NotificationManager.error(
            "Erro ao alterar a senha. Tente novamente.",
          );
        }
      });
    }
  };

  toggleInputPassword(inputValue) {
    const firstInput = !this.state.showPassword.firstInput;
    const secondInput = !this.state.showPassword.secondInput;

    if (inputValue === 1) {
      this.setState({ showPassword: { ...this.state.showPassword,
        firstInput } });
      return;
    }

    this.setState({ showPassword: { ...this.state.showPassword,
      secondInput } });
  }

  render() {
    const { newPassword, repeatPassword, programUrlParam } = this.state;

    return (
      <>
        <Header
          title="CRIAR SENHA"
          goBack={programUrlParam === "multiplik" ? "/multiplik/entrar" : "/"}
        />

        <div className={styles.newPasswordContainer}>
          <p>
            Por favor, crie uma senha de acesso
            {programUrlParam === "multiplik" && " ao Multiplik"}
          </p>

          <p>
            Ela deve conter pelo menos <b>8 dígitos</b>
          </p>

          <Form id="loginForm">
            <Form.Group controlId="formBasicLogin">
              <Form.Label className={styles.label}>
                <b>Senha</b>
              </Form.Label>

              <div className="d-flex align-items-center">
                <Form.Control
                  type={this.state.showPassword.firstInput ? "text" : "password"}
                  placeholder="Senha"
                  value={newPassword}
                  onChange={this.onNewPasswordChange}
                />
                <FontAwesomeIcon
                  color="#fff"
                  icon={this.state.showPassword.firstInput ? faEye : faEyeSlash}
                  className={styles.eyeIcon}
                  onClick={() => this.toggleInputPassword(1)}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label className={styles.label}>
                <b>Repita a senha</b>
              </Form.Label>

              <div className="d-flex align-items-center">
                <Form.Control
                  type={this.state.showPassword.secondInput ? "text" : "password"}
                  placeholder="Repita a senha"
                  value={repeatPassword}
                  onChange={this.onRepeatPasswordChange}
                />
                <FontAwesomeIcon
                  color="#fff"
                  className={styles.eyeIcon}
                  onClick={() => this.toggleInputPassword(2)}
                  icon={this.state.showPassword.secondInput ? faEye : faEyeSlash}
                />
              </div>

            </Form.Group>

            {this.state.messageError ? (
              <section id="errors" className="text-center mb-2">
                <div className="text-danger">{this.state.messageError}</div>
              </section>
            ) : (
              ""
            )}

            <div className="mt-4">
              <Button
                loading={this.state.loading}
                onClick={this.changePassword}
              >
                {this.state.loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                  />
                ) : "Confirmar"}
              </Button>
            </div>
          </Form>
        </div>

      </>
    );
  }
}

export default NewPassword;
