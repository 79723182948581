import React, { useState, useRef } from "react";
import moment from "moment";
import { Modal } from "lib-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import MoneyIcon from "assets/images/money-icon.png";

import { setEventOnDataLayer } from "utils/helpers/data-helper";

import { SpinnerIcon } from "components/ui/icons/icons";

import { Legends } from "./modal-content";
import useInfiniteScroll from "hooks/useInfiniteScroll";

import styles from "./styles.module.scss";

export default function Indications({
  invitations,
  pageSize,
  setPageSize,
  loadingInvitations,
  performance,
}) {
  const [showLegendsModal, setShowLegendsModal] = useState(false);
  const listRef = useRef();

  function getStatusBullets(e) {
    // Disbursed
    if (e.saleDetails?.paidAt) {
      return <img src={MoneyIcon} style={{ position: "relative", right: 2 }} />;
    }

    // Approved
    else if (
      (moment(e?.createdAt).isBefore(moment("2024-02-01 09:30:00")) &&
        e?.saleDetails?.cpApproved) ||
      (moment(e?.createdAt).isSameOrAfter(moment("2024-02-01 09:30:00")) &&
        moment(e?.createdAt).isBefore(moment("2024-05-23 12:15:00")) &&
        e.saleDetails?.signedAt) ||
      (moment(e?.createdAt).isAfter(moment("2024-05-23 12:15:00")) &&
        e?.saleDetails?.cpApproved)
    ) {
      return (
        <FontAwesomeIcon color="#00BA88" icon={faCircle} className={`status`} />
      );
    }

    // Declined
    else if (e.saleDetails?.declined) {
      return (
        <FontAwesomeIcon color="#2A2B35" icon={faCircle} className={`status`} />
      );
    }

    // Cancelled
    else if (e.saleDetails?.loanCancelledAt) {
      return (
        <FontAwesomeIcon color="#ED2E45" icon={faCircle} className={`status`} />
      );
    } else {
      return (
        <FontAwesomeIcon color="#BDBDBD" icon={faCircle} className={`status`} />
      );
    }
  }

  function loadMoreData() {
    if (invitations?.length != performance?.invitations) {
      setPageSize(pageSize + 5);
    }
  }

  function showLegend() {
    setEventOnDataLayer("mpk_legenda");
    setShowLegendsModal(true);
  }

  useInfiniteScroll(listRef, loadMoreData);

  return (
    <div className={styles.indications}>
      <div className={styles.divider} />

      <div className={styles.header}>
        <p className={styles.title}>
          Minhas indicações <span>({performance?.invitations})</span>
        </p>

        <p
          className={styles.legend}
          onClick={() => showLegend()}
          id="mpk_legend_button"
        >
          Informações
        </p>
      </div>

      <div className={styles.list} ref={listRef}>
        {invitations?.map((item) => (
          <div className={styles.item} key={item.sellerId + item.productItemId}>
            <div>
              {getStatusBullets(item)}
              <p className={styles.name}>{item.saleDetails.buyerName}</p>
            </div>
            <p className={styles.date}>
              {moment(item.createdAt).format("DD/MM/YYYY")}
            </p>
          </div>
        ))}
      </div>

      {invitations.length === 0 ? (
        <p className="text-center p-5">
          <b>Não há indicações para o período selecionado.</b>
        </p>
      ) : loadingInvitations ? (
        <SpinnerIcon
          className="loading"
          style={{
            width: "18px",
            height: "18px",
            color: "#ffa842",
            marginTop: "16px",
          }}
        />
      ) : (
        <>
          {invitations?.length != performance?.invitations ? (
            <div className={styles.viewMore}>
              <div className={styles.arrow}>
                <FontAwesomeIcon color="#BDBDBD" icon={faChevronDown} />
              </div>
            </div>
          ) : (
            ""
          )}

          {invitations?.length && performance?.invitations && (
            <p className={styles.viewMoreDescription}>
              {`Mostrando ${invitations.length} de ${performance?.invitations}`}
            </p>
          )}
        </>
      )}

      <Modal isShown={showLegendsModal} hide={() => setShowLegendsModal(false)}>
        <Legends getStatusBullets={getStatusBullets} />
      </Modal>
    </div>
  );
}
